"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.List = exports.Symbol = exports.Number = exports.AST = void 0;
class AST {
    toPlain() {
        return this.visit({
            number: v => v.value,
            symbol: v => v.value,
            list: v => v.elements.map(e => e.toPlain()),
        });
    }
    static fromPlain(v) {
        if (v instanceof AST)
            return v;
        if (v instanceof Array)
            return new List(v.map(AST.fromPlain));
        if (typeof v == 'number')
            return new Number(v);
        if (typeof v == 'string') {
            return new Symbol(v);
        }
        throw new TypeError();
    }
}
exports.AST = AST;
class Number extends AST {
    constructor(value) {
        super();
        this.value = value;
    }
    visit(visitor) {
        return visitor.number(this);
    }
}
exports.Number = Number;
class Symbol extends AST {
    constructor(value) {
        super();
        this.value = value;
    }
    visit(visitor) {
        return visitor.symbol(this);
    }
}
exports.Symbol = Symbol;
Symbol.block = new Symbol('block');
Symbol.eachChoice = new Symbol('$each-choice');
Symbol.eachRange = new Symbol('$each-range');
Symbol.eachAngle = new Symbol('$each-angle');
Symbol.randomChoice = new Symbol('$random-choice');
Symbol.randomRange = new Symbol('$random-range');
Symbol.randomAngle = new Symbol('$random-angle');
class List extends AST {
    constructor(elements) {
        super();
        this.elements = elements;
    }
    visit(visitor) {
        return visitor.list(this);
    }
}
exports.List = List;
