"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.History = void 0;
class History {
    constructor(alloc, capacity) {
        this.alloc = alloc;
        this.head = 0;
        this.payload = new Array(capacity);
        for (let i = 0; i < this.payload.length; ++i)
            this.payload[i] = { array: [], length: 0 };
    }
    get capacity() {
        return this.payload.length;
    }
    putSnapshot(items, write) {
        this.head = (this.head + 1) % this.payload.length;
        const snapshot = this.payload[this.head];
        let i = 0;
        for (const item of items) {
            if (snapshot.array.length <= i)
                snapshot.array.push(this.alloc());
            write(item, snapshot.array[i]);
            ++i;
        }
        snapshot.length = i;
    }
    clear(release = false) {
        for (let i = 0; i < this.payload.length; ++i) {
            this.payload[i].length = 0;
            if (release)
                this.payload[i].array = [];
        }
    }
    *snapshot(index) {
        console.assert(index < this.payload.length);
        const snapshot = this.payload[(this.head - index + this.payload.length) % this.payload.length];
        for (let i = 0; i < snapshot.length; ++i)
            yield snapshot.array[i];
    }
}
exports.History = History;
