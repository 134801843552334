"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapStorage = void 0;
class MapStorage {
    constructor(payload, writable) {
        this.payload = payload;
        this.writable = writable;
    }
    read(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = this.payload.get(item);
            if (!result)
                throw new Error(`No item named ${item}`);
            return result;
        });
    }
    write(item, body) {
        return __awaiter(this, void 0, void 0, function* () {
            const oldBody = this.payload.get(item);
            if (oldBody != body)
                this.payload.set(item, body);
        });
    }
    delete(item) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.payload.has(item))
                return false;
            this.payload.delete(item);
            return true;
        });
    }
    items() {
        return __awaiter(this, void 0, void 0, function* () {
            return Array.from(this.payload.keys());
        });
    }
}
exports.MapStorage = MapStorage;
