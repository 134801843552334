"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntryPoint = void 0;
const preact_1 = require("preact");
const stats_1 = require("./effects/stats");
const explorer_1 = require("./effects/explorer");
const store_1 = require("./effects/store");
const simulator_1 = require("./effects/simulator");
const viewer_1 = require("./effects/viewer");
const main_1 = require("./main");
const EntryPoint = props => ((0, preact_1.h)(stats_1.RunStats, { stats: props.stats },
    (0, preact_1.h)(explorer_1.RunExplorer, { explorer: props.explorer },
        (0, preact_1.h)(simulator_1.RunSimulator, { simulator: props.simulator },
            (0, preact_1.h)(viewer_1.RunViewer, { viewer: props.viewer },
                (0, preact_1.h)(store_1.RunStore, { initialState: props.initialState },
                    (0, preact_1.h)(main_1.Main, null)))))));
exports.EntryPoint = EntryPoint;
