"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Button = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const Button = props => {
    const { disabled, onClick, children } = props;
    const handleClick = (0, hooks_1.useCallback)(() => !disabled && onClick(), [disabled, onClick]);
    return ((0, preact_1.h)("button", { className: (0, aphrodite_1.css)(styles.normal, disabled ? styles.disabled : styles.enabled), disabled: disabled, onClick: handleClick }, children));
};
exports.Button = Button;
const styles = aphrodite_1.StyleSheet.create({
    normal: {
        cursor: 'pointer',
        margin: '2px',
        padding: '3px 6px',
        border: 'none',
        transition: '0.2s',
    },
    enabled: {
        color: '#fff',
        background: 'rgba(255, 255, 255, 0.1)',
        ':hover': {
            background: 'rgba(255, 255, 255, 0.3)',
        },
        ':active': {
            background: 'rgba(255, 255, 255, 0.7)',
        },
    },
    disabled: {
        color: '#999',
        background: 'rgba(100, 100, 100, 0.1)',
    },
});
