"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.presetStates = exports.presetNames = exports.initialParticleState = exports.initialPrismState = exports.initialRendererState = exports.initialEditorState = exports.initialCoreState = exports.compileTransition = exports.deserializeState = exports.serializeState = exports.initialApplicationState = exports.useStore = exports.RunStore = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const Context = (0, preact_1.createContext)(undefined);
const RunStore = props => {
    const [state, setState] = (0, hooks_1.useState)(props.initialState);
    const update = (0, hooks_1.useCallback)((updater) => {
        setState(s => (Object.assign(Object.assign({}, s), (updater instanceof Function ? updater(s) : updater))));
    }, [setState]);
    return (0, preact_1.h)(Context.Provider, { value: { state, update } }, props.children);
};
exports.RunStore = RunStore;
const useStore = () => (0, hooks_1.useContext)(Context);
exports.useStore = useStore;
function initialApplicationState() {
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, exports.initialCoreState), exports.initialEditorState), exports.initialRendererState), exports.initialPrismState), exports.initialParticleState), exports.presetStates['stardust']);
}
exports.initialApplicationState = initialApplicationState;
function serializeState(state) {
    function structurallyEqual(a, b) {
        if (a === b)
            return true;
        if (Array.isArray(a) && Array.isArray(b)) {
            if (a.length !== b.length)
                return false;
            for (let i = 0; i < a.length; ++i) {
                if (!structurallyEqual(a[i], b[i]))
                    return false;
            }
            return true;
        }
        if (typeof a == 'object' && typeof b == 'object') {
            // We assume that a and b have same props since these are part of ApplicationState.
            for (const key in a) {
                if (!structurallyEqual(a[key], b[key]))
                    return false;
            }
            return true;
        }
        return false;
    }
    const data = Object.assign({}, state);
    // Drop unnecessary props
    delete data.isPaused;
    delete data.showStats;
    delete data.showGrid;
    delete data.cameraRevolve;
    delete data.floorTransition;
    delete data.editorNotification;
    delete data.editorCompilation;
    delete data.generatorGeneration;
    delete data.explorer;
    if (data.generateAutomatically) {
        delete data.editingItem;
        delete data.editingCode;
    }
    // Drop props which have the default value
    const initialState = initialApplicationState();
    for (const key in initialState) {
        if (structurallyEqual(initialState[key], data[key])) {
            delete data[key];
        }
    }
    const json = JSON.stringify(data);
    return btoa(unescape(encodeURIComponent(json)));
}
exports.serializeState = serializeState;
function deserializeState(data) {
    const json = decodeURIComponent(escape(atob(data)));
    const state = JSON.parse(json);
    if (state.generateAutomatically === false)
        state.editorCompilation = ['required', 0];
    return state;
}
exports.deserializeState = deserializeState;
function compileTransition({ init, center: l, exponent, }) {
    const tail = 1 - init;
    const r = 1 - l;
    const p = Math.exp(exponent);
    const x1 = l * tail;
    const x2 = r * tail;
    const initx = init + x1;
    return n => n < init
        ? 1 - Math.pow((1 - n / init), p)
        : n < initx
            ? l * (1 - Math.pow(((n - init) / x1), p)) + r
            : n < 1
                ? r * Math.pow((1 - (n - initx) / x2), p)
                : 0;
}
exports.compileTransition = compileTransition;
exports.initialCoreState = {
    showStartPlay: true,
    comments: '',
    isPaused: true,
    showStats: false,
    showGrid: true,
    stepsPerSecond: 90,
    stepsPerUpdate: 1,
    cameraRevolve: true,
    floorTransition: false,
    urlParam: '',
};
exports.initialEditorState = {
    editingItem: '',
    editingCode: '',
    editorNotification: '',
    editorCompilation: ['none'],
    generatorGeneration: 0,
    generatorStrength: 350,
    generateAutomatically: true,
    explorer: [],
};
exports.initialRendererState = {
    fieldOfView: 70,
    antialias: false,
    bloomEffect: false,
    bloomStrength: 0,
    bloomThreshold: 0,
    bloomRadius: 0,
};
exports.initialPrismState = {
    prism: false,
    prismHueOffset: 0,
    prismHueTransition: 0,
    prismSaturation: 0.3,
    prismLightness: 0.8,
    prismSnapshotOffset: 0,
    prismTrailLength: 40,
    prismTrailStep: 2,
    prismTrailAttenuation: { init: 0.6, center: 1, exponent: -1 },
};
exports.initialParticleState = {
    particle: false,
    particleHueOffset: 0,
    particleHueTransition: 0,
    particleSaturation: 0.8,
    particleLightness: 0.7,
    particleDof: false,
    particleDofFocus: 0.15,
    particleDofAperture: 2,
    particleSizeAttenuation: true,
    particleSizeTransition: { init: 0, center: 0, exponent: 1 },
    particleCoreRadius: 1.7,
    particleCoreSharpness: 0,
    particleShellRadius: 0.8,
    particleShellLightness: 0.85,
    particleSnapshotOffset: 0,
    particleTrailLength: 32,
    particleTrailAttenuation: { init: 0, center: 0, exponent: 1 },
    particleTrailDiffusionScale: 6,
    particleTrailDiffusionTransition: { init: 0, center: 0, exponent: -1 },
    particleTrailDiffusionFineness: 3,
    particleTrailDiffusionShakiness: 3,
};
exports.presetNames = ['unified', 'neon', 'stardust'];
exports.presetStates = {
    unified: {
        antialias: true,
        bloomEffect: true,
        bloomStrength: 0.8,
        bloomThreshold: 0.5,
        bloomRadius: 1,
        prism: true,
        prismHueOffset: 0,
        prismHueTransition: 0,
        prismSaturation: 0.4,
        prismLightness: 0.4,
        prismSnapshotOffset: 0,
        prismTrailLength: 24,
        prismTrailStep: 1,
        prismTrailAttenuation: { init: 0.2, center: 1, exponent: -2 },
        particle: true,
        particleHueOffset: 0,
        particleHueTransition: -60,
        particleSaturation: 0.7,
        particleLightness: 0.6,
        particleDof: false,
        particleSizeAttenuation: true,
        particleSizeTransition: { init: 0, center: 1, exponent: -1 },
        particleCoreRadius: 1.2,
        particleCoreSharpness: 3,
        particleShellRadius: 20,
        particleShellLightness: 0.08,
        particleSnapshotOffset: 10,
        particleTrailLength: 55,
        particleTrailAttenuation: { init: 0.3, center: 1, exponent: 0.5 },
        particleTrailDiffusionScale: 30,
        particleTrailDiffusionTransition: { init: 0, center: 0, exponent: -2 },
        particleTrailDiffusionFineness: 3.5,
        particleTrailDiffusionShakiness: 2,
        stepsPerUpdate: 1,
    },
    neon: {
        stepsPerUpdate: 1.5,
        antialias: false,
        bloomEffect: false,
        prism: false,
        particle: true,
        particleHueTransition: 0,
        particleSaturation: 0.9,
        particleLightness: 0.75,
        particleDof: true,
        particleDofFocus: 0.15,
        particleDofAperture: 1,
        particleSizeAttenuation: true,
        particleSizeTransition: { init: 0, center: 1, exponent: 3 },
        particleCoreRadius: 0.4,
        particleCoreSharpness: 3,
        particleShellRadius: 6.6,
        particleShellLightness: 0.07,
        particleSnapshotOffset: 0,
        particleTrailLength: 90,
        particleTrailAttenuation: { init: 0.1, center: 1, exponent: 1 },
        particleTrailDiffusionScale: 10,
        particleTrailDiffusionTransition: { init: 0.5, center: 1, exponent: 1.5 },
        particleTrailDiffusionFineness: 3,
        particleTrailDiffusionShakiness: 3,
    },
    stardust: {
        stepsPerUpdate: 0.6,
        antialias: false,
        bloomEffect: true,
        bloomStrength: 0.8,
        bloomThreshold: 0,
        bloomRadius: 1,
        prism: false,
        particle: true,
        particleHueTransition: -160,
        particleSaturation: 1,
        particleLightness: 0.7,
        particleDof: false,
        particleDofFocus: 0.15,
        particleDofAperture: 0,
        particleSizeAttenuation: true,
        particleSizeTransition: { init: 0, center: 0, exponent: 0.5 },
        particleCoreRadius: 0.8,
        particleCoreSharpness: 1,
        particleShellRadius: 5,
        particleShellLightness: 0.06,
        particleSnapshotOffset: 0,
        particleTrailLength: 120,
        particleTrailAttenuation: { init: 1, center: 1, exponent: 3 },
        particleTrailDiffusionScale: 100,
        particleTrailDiffusionTransition: { init: 0, center: 0, exponent: -3 },
        particleTrailDiffusionFineness: 0.5,
        particleTrailDiffusionShakiness: 3.5,
    },
};
