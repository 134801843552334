"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextField = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const TextField = props => {
    const { value, onChange, multiline, style } = props;
    const handleChange = (0, hooks_1.useCallback)((ev) => onChange(ev.target.value), [onChange]);
    return multiline ? ((0, preact_1.h)("textarea", { className: (0, aphrodite_1.css)(styles.normal, styles.multiline), style: style, value: value, onInput: handleChange })) : ((0, preact_1.h)("input", { type: "text", className: (0, aphrodite_1.css)(styles.normal), style: style, value: value, onInput: handleChange }));
};
exports.TextField = TextField;
const styles = aphrodite_1.StyleSheet.create({
    normal: {
        background: 'none',
        margin: '0 3px',
        borderStyle: 'solid',
        borderWidth: '0 0 1px',
        borderColor: '#555',
        color: '#fff',
        fontSize: '16px',
        fontFamily: 'monospace',
        transition: 'border 0.2s',
        ':hover': {
            borderColor: '#aaa',
        },
    },
    multiline: {
        borderWidth: '1px',
        background: 'rgba(0, 0, 0, 0.4)',
        padding: '5px',
    },
});
