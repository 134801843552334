"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast = void 0;
// src/components/Toast.tsx
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const Toast = ({ message, duration = 3000 }) => {
    const [visible, setVisible] = (0, hooks_1.useState)(false);
    (0, hooks_1.useEffect)(() => {
        setVisible(true);
        const hideTimeout = setTimeout(() => setVisible(false), duration);
        return () => clearTimeout(hideTimeout);
    }, [message, duration]);
    return ((0, preact_1.h)("div", { style: Object.assign(Object.assign({}, styles.toast), (visible ? styles.show : {})) }, message));
};
exports.Toast = Toast;
const styles = {
    toast: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#FF4500',
        color: '#333',
        padding: '10px 20px',
        borderRadius: '5px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        opacity: 0,
        transition: 'opacity 0.3s ease-in-out',
        maxWidth: '300px',
        wordWrap: 'break-word',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000, // Ensure it appears above other elements
    },
    show: {
        opacity: 1,
    },
};
