"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toggle = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const Toggle = props => {
    const { disabled, value, onChange, style, children } = props;
    const handleClick = (0, hooks_1.useCallback)(() => !disabled && onChange(!value), [disabled, value, onChange]);
    return ((0, preact_1.h)("div", { class: (0, aphrodite_1.css)(styles.normal, disabled ? styles.disabled : styles.enabled, value && (disabled ? styles.disabledChecked : styles.enabledChecked)), style: style, onClick: handleClick }, children));
};
exports.Toggle = Toggle;
const styles = aphrodite_1.StyleSheet.create({
    normal: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '2px 4px',
        transition: '0.2s',
        border: '1px solid rgba(255, 255, 255, 0)',
        ':after': {
            content: '""',
            display: 'block',
            width: '7px',
            height: '7px',
            margin: '0 4px 0 12px',
            transform: 'rotate(45deg)',
            transition: '0.2s',
        },
    },
    enabled: {
        color: '#aaa',
        ':hover': {
            background: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'rgba(255, 255, 255, 0.3)',
        },
        ':active': {
            background: 'rgba(255, 255, 255, 0.3)',
            borderColor: 'rgba(255, 255, 255, 0.7)',
        },
        ':after': {
            border: '1px solid #555',
        },
        ':hover:after': {
            borderColor: '#aaa',
        },
        ':active:after': {
            borderColor: '#fff',
        },
    },
    enabledChecked: {
        ':after': {
            borderColor: '#aaa',
            background: 'rgba(255, 255, 255, 0.3)',
        },
        ':hover:after': {
            borderColor: '#fff',
            background: '#aaa',
        },
        ':active:after': {
            borderColor: '#aaa',
        },
    },
    disabled: {
        color: 'rgba(255, 255, 255, 0.1)',
        ':after': {
            border: '1px solid rgba(255, 255, 255, 0.1)',
        },
    },
    disabledChecked: {
        ':after': {
            borderColor: 'rgba(255, 255, 255, 0.3)',
            background: 'rgba(255, 255, 255, 0.1)',
        },
    },
});
