"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scene = void 0;
const THREE = require("three");
const history_1 = require("./aux/history");
const particles_1 = require("./particles");
const bgparticles_1 = require("./bgparticles");
const sky_1 = require("./sky");
const bg_shader_1 = require("./aux/bg-shader");
function allocateDot() {
    return {
        seed: 0,
        lifeTime: 0,
        position: new THREE.Vector3(),
        rotation: new THREE.Quaternion(),
        hue: 0,
        opacity: 0,
    };
}
class Scene extends THREE.Scene {
    constructor(camera) {
        super();
        this.prismOptions = {
            saturation: 0.5,
            lightness: 0.5,
            snapshotOffset: 0,
            hueOffset: 0,
            hueTransition: 0,
            trailLength: 1,
            trailStep: 1,
            trailAttenuation: (x) => 1 - x,
        };
        this.particleOptions = {
            saturation: 0.5,
            lightness: 0.5,
            sizeTransition: (x) => 1 - x,
            snapshotOffset: 10,
            hueOffset: 0,
            hueTransition: 0,
            trailLength: 1,
            trailAttenuation: (x) => 1 - x,
            trailDiffusionScale: 0,
            trailDiffusionTransition: (x) => 1 - x,
            trailDiffusionShakiness: 0,
        };
        this.stateNeedsUpdate = false;
        this.gu = {
            time: { value: 0 },
        };
        this.clock = new THREE.Clock();
        this.fog = new THREE.FogExp2(0x000000, 0.003);
        this.history = new history_1.History(allocateDot, 300);
        this.particles = new particles_1.Particles(80000);
        this.add(this.particles);
        const material = (0, bg_shader_1.createMaterial)(this.gu);
        this.bgParticles = new THREE.Points(bgparticles_1.cylinderGeometry, material);
        this.add(this.bgParticles);
        this.add(sky_1.skyDome);
        this.particles.mat.setCameraClip(camera.near, camera.far);
    }
    update() {
        if (this.stateNeedsUpdate) {
            this.stateNeedsUpdate = false;
            this.updateState();
        }
    }
    updateState() {
        let t = this.clock.getElapsedTime() * 0.5;
        this.gu.time.value = t * Math.PI;
        const bgPositions = this.bgParticles.geometry.attributes.position.array;
        for (let i = 1; i < bgPositions.length; i += 3) {
            bgPositions[i] -= 0.5; // Move particle down
            if (bgPositions[i] < -200) { // Reset particle to top if it goes below a certain point
                bgPositions[i] = 200;
            }
        }
        this.bgParticles.geometry.attributes.position.needsUpdate = true;
        const hsla = new THREE.Vector4();
        if (this.particles.visible) {
            const { saturation, lightness, sizeTransition, snapshotOffset, hueOffset, hueTransition, trailLength, trailAttenuation, trailDiffusionScale, trailDiffusionTransition, trailDiffusionShakiness, } = this.particleOptions;
            const particles = this.particles.beginUpdateState();
            for (let i = 0; i < trailLength; i++) {
                const t = i / (trailLength - 0.9);
                const l = trailAttenuation(t);
                const f = (1 - trailDiffusionTransition(t)) * trailDiffusionScale;
                const s = sizeTransition(t);
                for (const dot of this.history.snapshot(i + snapshotOffset)) {
                    if (dot.opacity == 0)
                        continue;
                    const hue = (dot.hue + hueOffset + (hueTransition * i) / trailLength) / 360;
                    hsla.set(hue, saturation, lightness * l, dot.opacity);
                    particles.put(dot.position, hsla, f, dot.lifeTime * trailDiffusionShakiness, s);
                }
            }
            particles.complete();
        }
    }
    setSize(width, height) {
        this.particles.mat.setSize(width, height);
    }
}
exports.Scene = Scene;
