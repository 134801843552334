"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Simulator = exports.CompileError = exports.ParseError = exports.compile = exports.load = exports.dump = exports.generate = exports.print = exports.parse = exports.Field = exports.Behavior = exports.Particle = void 0;
const dsl = require("./dsl");
const dslParser = require("./dsl-parser");
const dslPrinter = require("./dsl-printer");
const dslCompiler = require("./dsl-compiler");
const particle_1 = require("./particle");
Object.defineProperty(exports, "Particle", { enumerable: true, get: function () { return particle_1.Particle; } });
Object.defineProperty(exports, "Behavior", { enumerable: true, get: function () { return particle_1.Behavior; } });
Object.defineProperty(exports, "Field", { enumerable: true, get: function () { return particle_1.Field; } });
const patternGenerator = require("./pattern-generator");
const text_1 = require("./text");
const printer = new dslPrinter.Printer();
const compiler = new dslCompiler.Compiler();
function parse(text) {
    return dslParser.parseExact(dslParser.program, text);
}
exports.parse = parse;
function print(program) {
    return printer.printProgram(program);
}
exports.print = print;
function generate(strength) {
    return patternGenerator.generate(strength);
}
exports.generate = generate;
function dump(program) {
    return program.map(e => e.toPlain());
}
exports.dump = dump;
function load(program) {
    return program.map(a => dsl.AST.fromPlain(a));
}
exports.load = load;
function compile(program) {
    return compiler.compileProgram(program);
}
exports.compile = compile;
exports.ParseError = dslParser.ParseError;
exports.CompileError = dslCompiler.CompileError;
class Simulator {
    constructor() {
        this.field = new particle_1.Field();
        this.pattern = compile([]);
    }
    get closed() {
        return this.field.closed;
    }
    get particles() {
        return this.field;
    }
    reset() {
        this.field.clear();
    }
    update(deltaTime) {
        this.field.update(deltaTime);
    }
    emitRootParticle(x, y, z) {
        const behavior = this.pattern([0, 1]);
        const particle = new particle_1.Particle(behavior);
        particle.translate(x, y, z);
        this.field.add(particle);
    }
    generatePattern(strength, clear) {
        const program = generate(strength);
        const code = print(program);
        const { success, message } = this.compilePattern(program, clear);
        console.assert(success, message);
        return { code };
    }
    compilePattern(program, clear) {
        try {
            program = typeof program == 'string' ? parse(program) : program;
            this.pattern = compile(program);
            if (clear)
                this.field.clear();
            return { success: true, message: 'Successfully compiled.' };
        }
        catch (e) {
            const message = e instanceof exports.ParseError
                ? `Parse error: ${e.message}`
                : e instanceof exports.CompileError
                    ? `Compile error: ${e.message}`
                    : e instanceof Error
                        ? `Unknown error: ${e.message}`
                        : '${e}';
            return { success: false, message };
        }
    }
    setComments(comments) {
        (0, text_1.setComments)(comments);
    }
}
exports.Simulator = Simulator;
