"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Easing = void 0;
class Easing {
    constructor(f) {
        this.f = f;
    }
    // We treat that any transitions complete at a positive infinitesimal when n = 0.
    // This is why Behavior.update must be called with arguments that satisfy `start < end`.
    at(a, n = 1) {
        if (n == 0)
            return a <= 0 ? 0 : 1;
        return this.f(Math.max(0, Math.min(1, a / n)));
    }
    delta(a, b, n = 1) {
        if (n == 0)
            return a <= 0 && 0 < b ? 1 : b <= 0 && 0 < a ? -1 : 0;
        return this.at(b, n) - this.at(a, n);
    }
}
exports.Easing = Easing;
Easing.linear = new Easing(t => t);
Easing.easeIn = new Easing(t => t * t);
Easing.easeOut = new Easing(t => t * (2 - t));
Easing.easeInOut = new Easing(t => (t < 0.5 ? 2 * t * t : (4 - 2 * t) * t - 1));
