"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormComponent = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const api_1 = require("./api");
const toast_1 = require("./toast");
const FormComponent = ({ onSubmit, id }) => {
    const [values, setValues] = (0, hooks_1.useState)({ comments: '', sentences: [] });
    const [errors, setErrors] = (0, hooks_1.useState)({ comments: '', sentences: [] });
    const [toastMessage, setToastMessage] = (0, hooks_1.useState)(null);
    const handleChange = (e) => {
        const { name, value } = e.target;
        const sentences = value
            .split(/(?<=[。！？,.，?])/)
            .filter(Boolean)
            .map((sentence) => sentence.replace(/[。！？,.，?]/g, '')); // Remove punctuation
        setValues(Object.assign(Object.assign({}, values), { [name]: value, sentences }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const newErrors = { comments: '', sentences: [] };
        if (!values.comments) {
            setToastMessage('留言是必填项');
            newErrors.comments = '留言是必填项';
        }
        setErrors(newErrors);
        if (newErrors.comments)
            return;
        if (!id) {
            setToastMessage('无效id');
            return;
        }
        const v = values.sentences.join(',');
        (0, api_1.generateOrder)(id, v)
            .then(response => {
            if (response.code === 0) {
                onSubmit(true);
            }
            else if (response.code === 10000) {
                setToastMessage('输入已经超过4次，无法再次编辑');
            }
            else {
                setToastMessage('提交失败，返回异常');
            }
        })
            .catch(() => {
            setToastMessage('提交失败，网络异常');
        });
    };
    return ((0, preact_1.h)("div", { style: styles.container },
        (0, preact_1.h)("form", { onSubmit: handleSubmit },
            (0, preact_1.h)("div", null,
                (0, preact_1.h)("textarea", { name: "comments", value: values.comments, placeholder: "\u8BF7\u8F93\u5165\u7559\u8A00", onInput: handleChange, style: styles.textarea }),
                errors.comments && (0, preact_1.h)("div", { style: styles.errorText }, errors.comments)),
            (0, preact_1.h)("button", { type: "submit", style: styles.submitButton }, "\u63D0\u4EA4"),
            (0, preact_1.h)("div", { style: styles.instructions },
                "\u8BF4\u660E\uFF1A",
                (0, preact_1.h)("br", null),
                (0, preact_1.h)("p", null,
                    "1. \u6B64\u7559\u8A00\u4EC5\u652F\u6301",
                    (0, preact_1.h)("span", { style: { color: 'red' } }, "\u4FEE\u65394\u6B21"),
                    "\uFF0C\u82E5\u8981\u4FEE\u6539\u7559\u8A00\uFF0C\u8BF7\u5728\u7279\u6548\u754C\u9762\u4E09\u51FB\u5C4F\u5E55\u4FEE\u6539\u3002"),
                (0, preact_1.h)("p", null, "2. \u81EA\u5B9A\u4E49\u8F93\u5165\u60A8\u7684\u7559\u8A00\uFF0C\u7559\u8A00\u5C06\u6839\u636E\u6807\u70B9\u7B26\u53F7\u81EA\u52A8\u8FDB\u884C\u62C6\u5206\uFF0C\u4F5C\u4E3A\u70DF\u82B1\u77ED\u53E5\u3002"))),
        (0, preact_1.h)("div", { style: styles.sentencesContainer }, values.sentences.map((sentence, index) => ((0, preact_1.h)("div", { key: index, style: styles.sentence }, sentence)))),
        toastMessage && (0, preact_1.h)(toast_1.Toast, { message: toastMessage })));
};
exports.FormComponent = FormComponent;
const styles = {
    container: {
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        boxSizing: 'border-box',
        display: 'block', // 默认 block 布局，可以省略
    },
    textarea: {
        padding: '12px',
        width: '100%',
        height: '180px',
        borderRadius: '8px',
        border: '1px solid #ddd',
        fontSize: '16px',
        outline: 'none',
        boxSizing: 'border-box',
    },
    submitButton: {
        padding: '14px 30px',
        backgroundColor: '#FF4500',
        color: 'white',
        border: 'none',
        borderRadius: '8px',
        cursor: 'pointer',
        fontSize: '18px',
        width: '100%',
        textAlign: 'center',
        marginTop: '20px', // 增加间距
    },
    errorText: {
        color: 'red',
        fontSize: '12px',
    },
    '@media (max-width: 600px)': {
        container: {
            padding: '10px',
        },
        label: {
            fontSize: '14px',
        },
        input: {
            fontSize: '14px',
        },
        textarea: {
            fontSize: '14px',
            height: '120px', // 手机屏幕上调整文本框的高度
        },
        submitButton: {
            fontSize: '16px',
        },
        instructions: {
            fontSize: '12px',
        },
    },
    sentencesContainer: {
        marginTop: '20px',
    },
    sentence: {
        padding: '8px',
        borderBottom: '1px solid #ddd',
    },
    instructions: {
        marginTop: '10px',
        fontSize: '14px',
        color: '#555',
    },
};
