"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Main = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const components_1 = require("./components");
const stats_1 = require("./effects/stats");
const store_1 = require("./effects/store");
const screen_1 = require("./screen");
const system_1 = require("./system");
const startplay_1 = require("./startplay");
const form_1 = require("./form");
const api_1 = require("./api");
const Main = props => {
    const stats = (0, stats_1.useStats)();
    const store = (0, store_1.useStore)();
    const update = store.update;
    const { showStats } = store.state;
    const [isFormSubmitted, setIsFormSubmitted] = (0, hooks_1.useState)(false);
    const [isValidQR, setIsValidQR] = (0, hooks_1.useState)(false);
    const [times, setTimes] = (0, hooks_1.useState)(0);
    const [id, setId] = (0, hooks_1.useState)(null);
    (0, hooks_1.useEffect)(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        setId(id);
        if (id) {
            (0, api_1.fetchData)(id)
                .then((data) => {
                if (data.code === 0) {
                    // 有效验证码
                    setIsValidQR(true);
                }
                else {
                    // 无效验证码
                    setIsValidQR(false);
                }
            })
                .catch((error) => setIsValidQR(false));
            (0, api_1.getTimes)(id)
                .then((data) => {
                if (data.code === 0) {
                    setTimes(data.data);
                }
            })
                .catch((error) => setTimes(0));
        }
        else {
            // 无效验证码
            setIsValidQR(false);
        }
    }, []);
    const handleFormSubmit = (submitted) => {
        setIsFormSubmitted(submitted);
    };
    const togglePauseAndCameraRevolve = (0, hooks_1.useCallback)((ev) => {
        ev.preventDefault();
        update(state => ({
            isPaused: !state.isPaused,
            cameraRevolve: !state.cameraRevolve,
        }));
    }, [update]);
    (0, system_1.useSystem)();
    return ((0, preact_1.h)("div", null, isValidQR ? (isFormSubmitted || times >= 3 ? ((0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.container), onContextMenu: togglePauseAndCameraRevolve },
        (0, preact_1.h)(screen_1.Screen, null),
        (0, preact_1.h)(components_1.Mount, { className: (0, aphrodite_1.css)(styles.stats, showStats && styles.statsShow), dom: stats.dom }),
        (0, preact_1.h)(startplay_1.StartPlay, { id: id }))) : ((0, preact_1.h)(form_1.FormComponent, { onSubmit: handleFormSubmit, id: id }))) : ((0, preact_1.h)("div", null, "\u65E0\u6548\u9A8C\u8BC1\u7801"))));
};
exports.Main = Main;
const styles = aphrodite_1.StyleSheet.create({
    container: {
        fontSize: '12px',
        userSelect: 'none',
        MozUserSelect: 'none',
        MsUserSelect: 'none',
        position: 'fixed',
        background: '#000',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    },
    stats: {
        display: 'none',
    },
    statsShow: {
        display: 'block',
    },
});
