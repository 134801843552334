"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAnimationFrame = void 0;
const hooks_1 = require("preact/hooks");
function useAnimationFrame(onUpdate) {
    const timeRef = (0, hooks_1.useRef)(0);
    const requestRef = (0, hooks_1.useRef)(0);
    const callbackRef = (0, hooks_1.useRef)(onUpdate);
    callbackRef.current = onUpdate;
    (0, hooks_1.useEffect)(() => {
        function update() {
            const now = performance.now();
            const deltaTime = now - timeRef.current;
            callbackRef.current(deltaTime / 1000);
            timeRef.current = now;
            requestRef.current = requestAnimationFrame(update);
        }
        timeRef.current = performance.now();
        requestRef.current = requestAnimationFrame(update);
        return () => cancelAnimationFrame(requestRef.current);
    }, []);
}
exports.useAnimationFrame = useAnimationFrame;
