"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Field = exports.Behavior = exports.Particle = void 0;
const math = require("gl-matrix");
const easing_1 = require("./aux/easing");
// let Cd = 0.47; // 空气阻力系数 Cd 固定值 球体 Cd = 0.47
// let rho = 1.22; // 空气密度 ρ 固定值 标准大气压下 ρ = 1.22 kg/m^3
// let A = Math.PI / 10000; // 迎风面积 A = πr^2 r = 0.01m
const constantValue = 0.0000900238; // -0.5 * Cd * A * rho
// const gravity = -0.2; // 重力加速度 m/s^2
let dt = 0.0167; // 时间间隔 s
class Particle {
    constructor(behavior) {
        this.behavior = behavior;
        this.position = math.vec3.create();
        this.rotation = math.quat.create();
        this.lifeTime = 0;
        this.speed = 0;
        this.opacity = 1;
        this.hue = 0;
        this.closed = false;
        this.vx = 0;
        this.vy = 0;
        this.vz = 0;
        this.gravity = -0.2;
        this.mass = 0.001;
        this.initPosition = math.vec3.create();
        this.id = Math.random();
    }
    setProperties(position, rotation, speed, hue, vx = 0, vy = 0, vz = 0, gravity = -0.2, mass = 0.01) {
        math.vec3.copy(this.position, position);
        math.quat.copy(this.rotation, rotation);
        this.speed = speed;
        this.hue = hue;
        this.vx = vx;
        this.vy = vy;
        this.vz = vz;
        this.gravity = gravity;
        this.mass = mass;
        //    math.vec3.set(Particle.tmpVec, x, y, z);
        //     math.vec3.transformQuat(Particle.tmpVec, Particle.tmpVec, this.rotation);
        //     math.vec3.add(this.position, this.position, Particle.tmpVec);
        math.vec3.copy(this.initPosition, position);
        math.vec3.add(this.initPosition, this.initPosition, [50, 0, 0]);
    }
    clone(behavior) {
        const p = new Particle(behavior);
        math.vec3.copy(p.position, this.position);
        math.quat.copy(p.rotation, this.rotation);
        p.speed = this.speed;
        p.hue = this.hue;
        p.vx = 0;
        p.vy = 0;
        p.vz = 0;
        p.gravity = this.gravity;
        p.mass = this.mass;
        return p;
    }
    translate(x, y, z) {
        math.vec3.set(Particle.tmpVec, x, y, z);
        math.vec3.transformQuat(Particle.tmpVec, Particle.tmpVec, this.rotation);
        math.vec3.add(this.position, this.position, Particle.tmpVec);
        return this;
    }
    rotate(xdeg, ydeg, zdeg) {
        math.quat.fromEuler(Particle.tmpQuat, xdeg, ydeg, zdeg);
        math.quat.mul(this.rotation, this.rotation, Particle.tmpQuat);
        return this;
    }
    // textExplode(endR: number, deltaR: number): this {
    //   if (math.vec3.distance(this.position, this.initPosition) === 0) {
    //     return this;
    //   }
    //   // if initPosition's distance to position is less than 5, then set position to initPosition
    //   if (math.vec3.distance(this.position, this.initPosition) < 5) {
    //     math.vec3.copy(this.position, this.initPosition);
    //     return this;
    //   }
    //   if (endR <= 0.6) {
    //     this.opacity = endR > 0.2 ? 0.8 : this.opacity;
    //     this.explode();
    //     return this;
    //   }
    //
    //   let targetX = this.initPosition[0];
    //   let targetY = this.initPosition[1];
    //   let targetZ = this.initPosition[2];
    //
    //   let currentX = this.position[0];
    //   let currentY = this.position[1];
    //   let currentZ = this.position[2];
    //
    //   let deltaX = (targetX - currentX) * deltaR * 10;
    //   let deltaY = (targetY - currentY) * deltaR * 10;
    //   let deltaZ = (targetZ - currentZ) * deltaR * 10;
    //   math.vec3.set(Particle.textTemVec, deltaX, deltaY, deltaZ);
    //   math.vec3.add(this.position, this.position, Particle.textTemVec);
    //   return this;
    // }
    // textExplode(): this {
    //   return thi
    // }
    explode() {
        let Fx = constantValue * this.vx * this.vx * this.vx / Math.abs(this.vx);
        let Fz = constantValue * this.vz * this.vz * this.vz / Math.abs(this.vz);
        let Fy = constantValue * this.vy * this.vy * this.vy / Math.abs(this.vy);
        Fx = isNaN(Fx) ? 0 : Fx;
        Fy = isNaN(Fy) ? 0 : Fy;
        Fz = isNaN(Fz) ? 0 : Fz;
        let ax = Fx / this.mass;
        let ay = this.gravity + (Fy / this.mass);
        let az = Fz / this.mass;
        this.vx += ax * dt;
        this.vy += ay * dt;
        this.vz += az * dt;
        let x = this.vx * dt * 10;
        let y = this.vy * dt * 10;
        let z = this.vz * dt * 10;
        math.vec3.set(Particle.explodeTemVec, x, y, z);
        math.vec3.add(this.position, this.position, Particle.explodeTemVec);
        return this;
    }
}
exports.Particle = Particle;
Particle.tmpVec = math.vec3.create();
Particle.tmpQuat = math.quat.create();
Particle.explodeTemVec = math.vec3.create();
Particle.textTemVec = math.vec3.create();
class Behavior {
    constructor() {
        this.easing = easing_1.Easing.linear;
        this.lifespan = 0;
    }
    // Constraint:
    // * First a caller must pass start = 0 to (re)initialize the behavior.
    // * A caller must not pass arguments that cannot satisfy `start < end`.
    // * A callee must return the remaining execution time if the execution of
    //   the behavior is completed, otherwise callee must return a negative number.
    update(field, particle, start, end) {
        return end - Math.max(this.lifespan, start);
    }
}
exports.Behavior = Behavior;
class Field {
    constructor() {
        this.payload = [];
        this.count = 0;
    }
    get closed() {
        for (let i = 0; i < this.count; ++i) {
            if (!this.payload[i].closed)
                return false;
        }
        return true;
    }
    [Symbol.iterator]() {
        return this.payload.slice(0, this.count)[Symbol.iterator]();
    }
    add(particle) {
        if (this.count < this.payload.length) {
            this.payload[this.count] = particle;
        }
        else {
            this.payload.push(particle);
        }
        ++this.count;
    }
    clear() {
        this.count = 0;
    }
    update(deltaTime) {
        if (deltaTime <= 0)
            return;
        for (let i = 0; i < this.count;) {
            const particle = this.payload[i];
            const start = particle.lifeTime;
            particle.lifeTime += deltaTime;
            const dead = particle.behavior.update(this, particle, start, particle.lifeTime) >= 0;
            particle.translate(0, 0, particle.speed * deltaTime);
            if (dead) {
                --this.count;
                this.payload[i] = this.payload[this.count];
            }
            else {
                ++i;
            }
        }
    }
}
exports.Field = Field;
