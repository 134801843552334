"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCodeGenerate = exports.useCodeLoad = exports.useCodeDelete = exports.useCodeSave = exports.useSystem = void 0;
const hooks_1 = require("preact/hooks");
const hooks_2 = require("./hooks");
const store_1 = require("./effects/store");
const stats_1 = require("./effects/stats");
const explorer_1 = require("./effects/explorer");
const simulator_1 = require("./effects/simulator");
const viewer_1 = require("./effects/viewer");
const bridge_1 = require("../bridge");
function useSystem() {
    useExplorerInitialization();
    useCompileRequestHandler();
    useViewerOptionApplier();
    (0, hooks_2.useAnimationFrame)(useSystemUpdater());
}
exports.useSystem = useSystem;
function useExplorerInitialization() {
    const store = (0, store_1.useStore)();
    const explorer = (0, explorer_1.useExplorer)();
    const update = store.update;
    (0, hooks_1.useEffect)(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            const storages = explorer.explore();
            const state = yield Promise.all(storages.map((storage) => __awaiter(this, void 0, void 0, function* () {
                const items = yield explorer.items(storage.path);
                return Object.assign(Object.assign({}, storage), { items });
            })));
            update({ explorer: state });
        }))();
    }, []);
}
function useCompileRequestHandler() {
    const store = (0, store_1.useStore)();
    const simulator = (0, simulator_1.useSimulator)();
    const compileTimeout = (0, hooks_1.useRef)(0);
    const update = store.update;
    const { editingCode, editorCompilation } = store.state;
    const commit = (0, hooks_1.useCallback)((code) => {
        const { message } = simulator.compilePattern(code, true);
        update({ editorNotification: message });
    }, [update, simulator]);
    (0, hooks_1.useEffect)(() => {
        switch (editorCompilation[0]) {
            case 'none':
                break;
            case 'required':
                const defer = editorCompilation[1];
                update({ editorCompilation: ['none'], editorNotification: '...' });
                clearTimeout(compileTimeout.current);
                compileTimeout.current = window.setTimeout(commit.bind(null, editingCode), defer);
                break;
            case 'cancelRequired':
                update({ editorCompilation: ['none'] });
                clearTimeout(compileTimeout.current);
                break;
        }
    }, [update, editingCode, editorCompilation]);
}
function useViewerOptionApplier() {
    const store = (0, store_1.useStore)();
    const viewer = (0, viewer_1.useViewer)();
    const { fieldOfView, antialias, bloomEffect, bloomStrength, bloomThreshold, bloomRadius } = store.state;
    (0, hooks_1.useEffect)(() => {
        const updateMatrix = viewer.camera.fov != fieldOfView;
        viewer.camera.fov = fieldOfView;
        if (updateMatrix)
            viewer.camera.updateProjectionMatrix();
        viewer.renderer.antialias = antialias;
        viewer.renderer.bloom = bloomEffect;
        viewer.renderer.bloomStrength = bloomStrength;
        viewer.renderer.bloomThreshold = bloomThreshold;
        viewer.renderer.bloomRadius = bloomRadius;
    }, [fieldOfView, antialias, bloomEffect, bloomStrength, bloomThreshold, bloomRadius]);
    const { showGrid } = store.state;
    (0, hooks_1.useEffect)(() => {
        // viewer.scene.grid.visible = showGrid;
    }, [showGrid]);
    const { prism, prismSaturation, prismLightness, prismSnapshotOffset, prismHueOffset, prismHueTransition, prismTrailLength, prismTrailStep, prismTrailAttenuation, } = store.state;
    (0, hooks_1.useEffect)(() => {
        viewer.scene.prismOptions.saturation = prismSaturation;
        viewer.scene.prismOptions.lightness = prismLightness;
        viewer.scene.prismOptions.snapshotOffset = prismSnapshotOffset;
        viewer.scene.prismOptions.hueOffset = prismHueOffset;
        viewer.scene.prismOptions.hueTransition = prismHueTransition;
        viewer.scene.prismOptions.trailLength = prismTrailLength;
        viewer.scene.prismOptions.trailStep = prismTrailStep;
        viewer.scene.prismOptions.trailAttenuation = (0, store_1.compileTransition)(prismTrailAttenuation);
        viewer.scene.stateNeedsUpdate = true;
    }, [
        prism,
        prismSaturation,
        prismLightness,
        prismSnapshotOffset,
        prismHueOffset,
        prismHueTransition,
        prismTrailLength,
        prismTrailStep,
        prismTrailAttenuation,
    ]);
    const { particle, particleSaturation, particleLightness, particleDof, particleDofFocus, particleDofAperture, particleSizeAttenuation, particleSizeTransition, particleCoreRadius, particleCoreSharpness, particleShellRadius, particleShellLightness, particleSnapshotOffset, particleHueOffset, particleHueTransition, particleTrailLength, particleTrailAttenuation, particleTrailDiffusionScale, particleTrailDiffusionTransition, particleTrailDiffusionFineness, particleTrailDiffusionShakiness, } = store.state;
    (0, hooks_1.useEffect)(() => {
        viewer.scene.particles.visible = particle;
        viewer.scene.particleOptions.saturation = particleSaturation;
        viewer.scene.particleOptions.lightness = particleLightness;
        viewer.scene.particleOptions.sizeTransition = (0, store_1.compileTransition)(particleSizeTransition);
        viewer.scene.particles.mat.changeOptions(particleDof, particleDofFocus, particleDofAperture, particleSizeAttenuation, Math.exp(particleTrailDiffusionFineness - 5), particleCoreRadius, particleCoreSharpness, particleShellRadius, particleShellLightness);
        viewer.scene.particleOptions.snapshotOffset = particleSnapshotOffset;
        viewer.scene.particleOptions.hueOffset = particleHueOffset;
        viewer.scene.particleOptions.hueTransition = particleHueTransition;
        viewer.scene.particleOptions.trailLength = particleTrailLength;
        viewer.scene.particleOptions.trailAttenuation = (0, store_1.compileTransition)(particleTrailAttenuation);
        viewer.scene.particleOptions.trailDiffusionScale = particleTrailDiffusionScale;
        viewer.scene.particleOptions.trailDiffusionTransition = (0, store_1.compileTransition)(particleTrailDiffusionTransition);
        viewer.scene.particleOptions.trailDiffusionShakiness = Math.exp(particleTrailDiffusionShakiness - 5);
        viewer.scene.stateNeedsUpdate = true;
    }, [
        particle,
        particleSaturation,
        particleLightness,
        particleDof,
        particleDofFocus,
        particleDofAperture,
        particleSizeAttenuation,
        particleSizeTransition,
        particleCoreRadius,
        particleCoreSharpness,
        particleShellRadius,
        particleShellLightness,
        particleSnapshotOffset,
        particleHueOffset,
        particleHueTransition,
        particleTrailLength,
        particleTrailAttenuation,
        particleTrailDiffusionScale,
        particleTrailDiffusionTransition,
        particleTrailDiffusionFineness,
        particleTrailDiffusionShakiness,
    ]);
}
function useSystemUpdater() {
    const store = (0, store_1.useStore)();
    const simulator = (0, simulator_1.useSimulator)();
    const viewer = (0, viewer_1.useViewer)();
    const stats = (0, stats_1.useStats)();
    const codeGenerate = useCodeGenerate(false);
    const totalSteps = (0, hooks_1.useRef)(0);
    const floorOffset = (0, hooks_1.useRef)(0);
    const { stepsPerSecond, stepsPerUpdate, isPaused, cameraRevolve, floorTransition, generateAutomatically, } = store.state;
    return (0, hooks_1.useCallback)((deltaTime) => {
        stats.begin();
        // if (cameraRevolve) viewer.camera.targetPosition.x += 0.05;
        if (!isPaused) {
            totalSteps.current += deltaTime * stepsPerSecond;
            while (totalSteps.current > stepsPerUpdate) {
                simulator.update(stepsPerUpdate);
                viewer.scene.history.putSnapshot(simulator.particles, bridge_1.copyParticle);
                totalSteps.current -= stepsPerUpdate;
            }
            viewer.scene.stateNeedsUpdate = true;
            if (simulator.closed) {
                if (generateAutomatically)
                    codeGenerate();
                simulator.emitRootParticle(0, floorOffset.current, 0);
            }
        }
        viewer.update();
        stats.end();
    }, [
        simulator,
        viewer,
        stats,
        codeGenerate,
        stepsPerSecond,
        stepsPerUpdate,
        isPaused,
        cameraRevolve,
        floorTransition,
        generateAutomatically,
    ]);
}
function useCodeSave() {
    const store = (0, store_1.useStore)();
    const explorer = (0, explorer_1.useExplorer)();
    const update = store.update;
    const { editingItem, editingCode } = store.state;
    return (0, hooks_1.useCallback)(() => __awaiter(this, void 0, void 0, function* () {
        const firstWritableStorage = explorer.explore().find(storage => storage.writable);
        if (!firstWritableStorage)
            return;
        yield explorer.write(firstWritableStorage.path, editingItem, editingCode);
        const items = yield explorer.items(firstWritableStorage.path);
        update(state => (Object.assign(Object.assign({}, state), { explorer: state.explorer.map(storage => storage.path == firstWritableStorage.path ? Object.assign(Object.assign({}, storage), { items }) : storage) })));
    }), [update, editingItem, editingCode, explorer]);
}
exports.useCodeSave = useCodeSave;
function useCodeDelete() {
    const store = (0, store_1.useStore)();
    const explorer = (0, explorer_1.useExplorer)();
    const update = store.update;
    return (0, hooks_1.useCallback)((path, item) => __awaiter(this, void 0, void 0, function* () {
        yield explorer.delete(path, item);
        update(state => (Object.assign(Object.assign({}, state), { explorer: state.explorer.map(storage => storage.path == path
                ? Object.assign(Object.assign({}, storage), { items: storage.items.filter(i => i != item) }) : storage) })));
    }), [update, explorer]);
}
exports.useCodeDelete = useCodeDelete;
function useCodeLoad() {
    const store = (0, store_1.useStore)();
    const explorer = (0, explorer_1.useExplorer)();
    const update = store.update;
    return (0, hooks_1.useCallback)((path, item) => __awaiter(this, void 0, void 0, function* () {
        const code = yield explorer.read(path, item);
        update({
            editingItem: item,
            editingCode: code,
            editorCompilation: ['required', 0],
            generateAutomatically: false,
        });
    }), [update, explorer]);
}
exports.useCodeLoad = useCodeLoad;
let configContent = null;
function loadConfigFile() {
    if (configContent === null) {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', 'config.txt', false); // Synchronous request
        xhr.send(null);
        if (xhr.status === 200) {
            configContent = xhr.responseText;
        }
        else {
            throw new Error('Failed to load config file');
        }
    }
    return configContent;
}
// Usage
function generateCode() {
    try {
        const configFileContent = loadConfigFile();
        console.log(configFileContent);
        return configFileContent;
    }
    catch (error) {
        console.error('Error loading config file:', error);
        return '';
    }
}
function useCodeGenerate(clear) {
    const store = (0, store_1.useStore)();
    const explorer = (0, explorer_1.useExplorer)();
    const simulator = (0, simulator_1.useSimulator)();
    const update = store.update;
    const { generatorGeneration, generatorStrength, comments } = store.state;
    if (!comments) {
        return () => {
        };
    }
    return (0, hooks_1.useCallback)(() => __awaiter(this, void 0, void 0, function* () {
        const code = generateCode();
        simulator.compilePattern(code, true);
        simulator.setComments(comments);
        const generation = generatorGeneration + 1;
        const item = `Generation ${generation}`;
        update(state => (Object.assign(Object.assign({}, state), { editingItem: item, editingCode: code, editorCompilation: ['cancelRequired'], explorer: state.explorer.map(storage => storage.path == 'history' ? Object.assign(Object.assign({}, storage), { items: storage.items.concat([item]) }) : storage), generatorGeneration: generation })));
        yield explorer.write('history', item, code);
    }), [update, clear, explorer, simulator, generatorGeneration, generatorStrength]);
}
exports.useCodeGenerate = useCodeGenerate;
