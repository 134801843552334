"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RadialTexture = void 0;
const THREE = require("three");
class RadialTexture {
    constructor(resolution = 512, division = 4) {
        this.resolution = resolution;
        this.division = division;
        this.px = 0;
        this.py = 0;
        this.canvas = document.createElement('canvas');
        this.canvas.width = resolution;
        this.canvas.height = resolution;
        this.context = this.canvas.getContext('2d');
        const r = resolution / 2;
        this.gradient = this.context.createRadialGradient(r, r, 0, r, r, r);
    }
    moveTo(x, y, f = t => t) {
        const s = this.resolution / this.division;
        x = Math.floor(x * s);
        y = Math.floor(y * s);
        if (x == this.px)
            return this;
        for (let i = this.px == 0 ? 0 : this.px + 1; i <= x; ++i) {
            const t = (i - this.px) / (x - this.px);
            const offset = s - i;
            const alpha = f(t) * (y - this.py) + this.py;
            this.gradient.addColorStop(offset / s, `rgba(255, 255, 255, ${alpha / s})`);
        }
        this.px = x;
        this.py = y;
        return this;
    }
    easeInTo(x, y, p = 2) {
        return this.moveTo(x, y, t => Math.pow(t, p));
    }
    easeOutTo(x, y, p = 2) {
        return this.moveTo(x, y, t => 1 - Math.pow(Math.abs(t - 1), p));
    }
    render() {
        this.context.fillStyle = this.gradient;
        this.context.fillRect(0, 0, this.resolution, this.resolution);
        const texture = new THREE.Texture(this.canvas);
        texture.needsUpdate = true;
        return texture;
    }
}
exports.RadialTexture = RadialTexture;
