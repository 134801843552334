"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reference = exports.examples = void 0;
const examples_1 = require("./examples");
const reference_1 = require("./reference");
function preset(text) {
    const map = new Map();
    let title;
    let body = '';
    for (const line of text.split(/\n/)) {
        if (line.startsWith('---')) {
            if (title)
                map.set(title, body.trim());
            title = line.substring(3).trim();
            body = '';
        }
        else {
            body += line + '\n';
        }
    }
    return map;
}
exports.examples = preset(examples_1.text);
exports.reference = preset(reference_1.text);
