"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.skyDome = void 0;
const THREE = require("three");
const StarrySkyShader_1 = require("./aux/StarrySkyShader");
let skyDomeRadius = 500.01;
let sphereMaterial = new THREE.ShaderMaterial({
    uniforms: {
        skyRadius: { value: skyDomeRadius },
        env_c1: { value: new THREE.Color('#0d1a2f') },
        env_c2: { value: new THREE.Color('#0f8682') },
        noiseOffset: { value: new THREE.Vector3(100.01, 100.01, 100.01) },
        starSize: { value: 0.01 },
        starDensity: { value: 0.09 },
        clusterStrength: { value: 0.2 },
        clusterSize: { value: 0.2 },
    },
    vertexShader: StarrySkyShader_1.StarrySkyShader.vertexShader,
    fragmentShader: StarrySkyShader_1.StarrySkyShader.fragmentShader,
    side: THREE.DoubleSide,
});
let sphereGeometry = new THREE.SphereGeometry(skyDomeRadius, 20, 20);
let skyDome = new THREE.Mesh(sphereGeometry, sphereMaterial);
exports.skyDome = skyDome;
