"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMouseWheelEvent = void 0;
const hooks_1 = require("preact/hooks");
function useMouseWheelEvent(containerRef, handler, inputs) {
    const callback = (0, hooks_1.useCallback)(handler, inputs);
    (0, hooks_1.useEffect)(() => {
        containerRef.current.addEventListener('wheel', callback);
        return () => containerRef.current.removeEventListener('wheel', callback);
    }, [callback]);
}
exports.useMouseWheelEvent = useMouseWheelEvent;
