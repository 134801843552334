"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setComments = exports.generateTextPositions = void 0;
const math = require("gl-matrix");
// 创建一个隐藏的 canvas 用来绘制文字
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
// 设置画布大小
canvas.width = 500;
canvas.height = 200;
let globalComments = [];
let currentTextIndex = 0;
function getText() {
    if (currentTextIndex >= globalComments.length) {
        currentTextIndex = 0;
    }
    return globalComments[currentTextIndex++];
}
function setComments(comments) {
    globalComments = comments.split(',');
}
exports.setComments = setComments;
function generateTextPositions() {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.font = 'italic 40px "Brush Script MT", cursive';
    ctx.fillText(getText(), 50, 100); // 使用填充而不是描边
    // 获取文字的像素数据
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    const textPositions = [];
    const particleSize = 2;
    for (let x = 0; x < canvas.width; x += particleSize) {
        for (let y = 0; y < canvas.height; y += particleSize) {
            const index = (x + y * canvas.width) * 4;
            const alpha = data[index + 3];
            // 只有不透明的像素会生成粒子
            if (alpha > 128) {
                const particle = math.vec3.fromValues(0, canvas.height / 2 - y, -x + canvas.width / 2);
                textPositions.push(particle);
            }
        }
    }
    const center = math.vec3.create();
    for (let i = 0; i < textPositions.length; i++) {
        math.vec3.add(center, center, textPositions[i]);
    }
    math.vec3.scale(center, center, 1 / textPositions.length);
    return { textPositions, center };
}
exports.generateTextPositions = generateTextPositions;
