"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accordion = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const Accordion = props => {
    const { initiallyOpened, header, children } = props;
    const [isOpened, setIsOpened] = (0, hooks_1.useState)(initiallyOpened || false);
    const handleToggle = (0, hooks_1.useCallback)(() => setIsOpened(isOpened => !isOpened), []);
    return ((0, preact_1.h)("div", null,
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.header, isOpened && styles.headerOpened), onClick: handleToggle }, header),
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.content, isOpened && styles.contentOpened) }, children)));
};
exports.Accordion = Accordion;
const styles = aphrodite_1.StyleSheet.create({
    header: {
        cursor: 'pointer',
        padding: '4px 4px 1px',
        transition: '0.2s',
        color: '#aaa',
        borderBottom: '1px solid #555',
        ':hover': {
            color: '#fff',
            borderColor: '#fff',
        },
        ':before': {
            content: '""',
            display: 'inline-block',
            margin: '0 2px 0 2px',
            borderStyle: 'solid',
            borderWidth: '4px',
            borderColor: 'transparent transparent transparent rgba(255, 255, 255, 0.3)',
            transition: '0.2s',
        },
        ':hover:before': {
            borderColor: 'transparent transparent transparent #ffffff',
        },
    },
    headerOpened: {
        ':before': {
            margin: '2px 4px 0 0',
            borderColor: 'rgba(255, 255, 255, 0.7) transparent transparent transparent',
        },
        ':hover:before': {
            borderColor: '#ffffff transparent transparent transparent',
        },
    },
    content: {
        margin: '4px 0',
        display: 'none',
    },
    contentOpened: {
        display: 'block',
    },
});
