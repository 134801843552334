"use strict";
const preact_1 = require("preact");
const Stats = require("stats.js");
const explorer_1 = require("./explorer");
const simulator_1 = require("./simulator");
const simulatorPresets = require("./simulator/presets");
const viewer_1 = require("./viewer");
const application_1 = require("./application");
const store_1 = require("./application/effects/store");
function emitter3d(container) {
    const explorer = new explorer_1.Explorer();
    explorer.mount('local', new explorer_1.NativeStorage(localStorage, 'e3d-user-'));
    explorer.mount('examples', new explorer_1.MapStorage(simulatorPresets.examples, false));
    explorer.mount('reference', new explorer_1.MapStorage(simulatorPresets.reference, false));
    explorer.mount('history', new explorer_1.MapStorage(new Map(), true));
    const stats = new Stats();
    stats.showPanel(0);
    const simulator = new simulator_1.Simulator();
    const viewer = new viewer_1.Viewer();
    let state = (0, store_1.initialApplicationState)();
    if (location.hash.length) {
        const data = decodeURIComponent(location.hash.slice(1));
        state = Object.assign(Object.assign({}, state), (0, store_1.deserializeState)(data));
    }
    (0, preact_1.render)((0, preact_1.h)(application_1.EntryPoint, { simulator: simulator, viewer: viewer, initialState: state, explorer: explorer, stats: stats }), container);
}
module.exports = emitter3d;
