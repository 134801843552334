"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StartPlay = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const store_1 = require("./effects/store");
const soundhandler_1 = require("./soundhandler");
const api_1 = require("./api");
const toast_1 = require("./toast");
const StartPlay = ({ id }) => {
    const store = (0, store_1.useStore)();
    const { update, state } = store;
    const [toastMessage, setToastMessage] = (0, hooks_1.useState)(null);
    // SoundHandler
    const soundHandler = new soundhandler_1.SoundHandler();
    if (!id) {
        setToastMessage('无效id');
        return null;
    }
    const handleClick = (0, hooks_1.useCallback)(() => {
        (0, api_1.getComments)(id)
            .then((response) => {
            if (response.code === 0) {
                update(state => ({
                    isPaused: false,
                    comments: response.data,
                    showStartPlay: false,
                }));
            }
            else {
                setToastMessage('获取留言失败');
            }
        }).catch((error) => {
            setToastMessage('获取留言失败 ' + error);
        });
        // play Background
        soundHandler.PlayBackground(0.3);
    }, [update]);
    if (!state.showStartPlay) {
        return null; // 如果状态为 false，则不渲染组件
    }
    return ((0, preact_1.h)("button", { className: (0, aphrodite_1.css)(styles.button), onClick: handleClick },
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.triangle) }),
        toastMessage && (0, preact_1.h)(toast_1.Toast, { message: toastMessage })));
};
exports.StartPlay = StartPlay;
const styles = aphrodite_1.StyleSheet.create({
    button: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '50px',
        height: '50px',
        borderRadius: '50%',
        background: 'rgba(255, 255, 255, 0.1)',
        border: '2px solid #fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
    },
    triangle: {
        width: 0,
        height: 0,
        borderLeft: '10px solid transparent',
        borderRight: '10px solid transparent',
        borderBottom: '20px solid rgba(255, 255, 255, 0.8)',
        borderRadius: '3px',
        transform: 'rotate(90deg)',
    },
});
