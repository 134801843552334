"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParallelBehavior = exports.SequentialBehavior = exports.RepeatBehavior = exports.LoopBehavior = exports.FireworksBehavior = exports.FlairBehavior = exports.CrackleVoiceBehavior = exports.BloomVoiceBehavior = exports.TextBehavior = exports.characterBehavior = exports.EmitBehavior = exports.RotateBehavior = exports.TextExplodeBehavior = exports.FireworksExplodeBehavior = exports.TranslateBehavior = exports.SwitchBehavior = exports.MultiplyBehavior = exports.AddBehavior = exports.SetBehavior = exports.NopBehavior = void 0;
const particle_1 = require("./particle");
const text_1 = require("./text");
const math = require("gl-matrix");
const soundhandler_1 = require("./soundhandler");
const soundHandler = new soundhandler_1.SoundHandler();
class NopBehavior extends particle_1.Behavior {
}
exports.NopBehavior = NopBehavior;
class SetBehavior extends particle_1.Behavior {
    constructor(targetValue, getValue, setValue) {
        super();
        this.targetValue = targetValue;
        this.getValue = getValue;
        this.setValue = setValue;
    }
    update(field, particle, start, end) {
        if (start == 0)
            this.initialValue = this.getValue(particle);
        const r = this.easing.at(end, this.lifespan);
        this.setValue(particle, this.initialValue * (1 - r) + this.targetValue * r);
        return super.update(field, particle, start, end);
    }
}
exports.SetBehavior = SetBehavior;
class AddBehavior extends particle_1.Behavior {
    constructor(value, addValue) {
        super();
        this.value = value;
        this.addValue = addValue;
    }
    update(field, particle, start, end) {
        const r = this.easing.delta(start, end, this.lifespan);
        this.addValue(particle, this.value * r);
        return super.update(field, particle, start, end);
    }
}
exports.AddBehavior = AddBehavior;
class MultiplyBehavior extends particle_1.Behavior {
    constructor(scale, multiplyScale) {
        super();
        this.scale = scale;
        this.multiplyScale = multiplyScale;
    }
    update(field, particle, start, end) {
        const r = this.easing.delta(start, end, this.lifespan);
        this.multiplyScale(particle, Math.pow(this.scale, r));
        return super.update(field, particle, start, end);
    }
}
exports.MultiplyBehavior = MultiplyBehavior;
class SwitchBehavior extends particle_1.Behavior {
    constructor(on) {
        super();
        this.on = on;
    }
    update(field, particle, start, end) {
        const r = this.easing.at(end, this.lifespan);
        if (r == 1)
            this.on(particle);
        return super.update(field, particle, start, end);
    }
}
exports.SwitchBehavior = SwitchBehavior;
class TranslateBehavior extends particle_1.Behavior {
    constructor(x, y, z) {
        super();
        this.x = x;
        this.y = y;
        this.z = z;
    }
    //easingOut
    update(field, particle, start, end) {
        const r = this.easing.delta(start, end, this.lifespan);
        particle.translate(this.x * r, this.y * r, this.z * r);
        return super.update(field, particle, start, end);
    }
}
exports.TranslateBehavior = TranslateBehavior;
class FireworksExplodeBehavior extends particle_1.Behavior {
    constructor() {
        super();
    }
    update(field, particle, start, end) {
        particle.explode();
        return super.update(field, particle, start, end);
    }
}
exports.FireworksExplodeBehavior = FireworksExplodeBehavior;
class TextExplodeBehavior extends particle_1.Behavior {
    constructor() {
        super();
    }
    update(field, particle, start, end) {
        particle.explode();
        return super.update(field, particle, start, end);
    }
}
exports.TextExplodeBehavior = TextExplodeBehavior;
class RotateBehavior extends particle_1.Behavior {
    constructor(xdeg, ydeg, zdeg) {
        super();
        this.xdeg = xdeg;
        this.ydeg = ydeg;
        this.zdeg = zdeg;
    }
    update(field, particle, start, end) {
        const r = this.easing.delta(start, end, this.lifespan);
        particle.rotate(this.xdeg * r, this.ydeg * r, this.zdeg * r);
        return super.update(field, particle, start, end);
    }
}
exports.RotateBehavior = RotateBehavior;
class EmitBehavior extends particle_1.Behavior {
    constructor(count, times, parallel, behaviorGen) {
        super();
        this.behaviorGen = behaviorGen;
        this.indices = [...Array(Math.floor(times))].map((_, t) => [...Array(Math.floor(count))].map((_, n) => [...Array(Math.floor(parallel))].map((_, p) => n + count * t + times * count * p)));
    }
    update(field, particle, start, end) {
        const l = this.easing.at(start, this.lifespan) * this.indices.length;
        const r = this.easing.at(end, this.lifespan) * this.indices.length;
        for (let i = Math.floor(r); l < i; --i) {
            for (const indices of this.indices[i - 1]) {
                for (const index of indices) {
                    const behavior = this.behaviorGen(index);
                    field.add(particle.clone(behavior));
                }
            }
        }
        return super.update(field, particle, start, end);
    }
}
exports.EmitBehavior = EmitBehavior;
class characterBehavior extends particle_1.Behavior {
    constructor(s, behavior) {
        super();
        this.s = s;
        this.behavior = behavior;
    }
    update(field, particle, start, end) {
        const { textPositions, center } = (0, text_1.generateTextPositions)();
        const targetCenter = particle.position;
        const translation = math.vec3.create();
        math.vec3.subtract(translation, targetCenter, center);
        for (let i = 0; i < textPositions.length; i++) {
            let vy = 1 - Math.random() * 2;
            let vx = Math.random();
            let vz = 1 - Math.random() * 2;
            let newParticle = new particle_1.Particle(this.behavior);
            let newPosition = math.vec3.clone(textPositions[i]);
            // 偏移到中心点
            math.vec3.add(newPosition, newPosition, translation);
            newParticle.setProperties(newPosition, particle.rotation, particle.speed, particle.hue, vx, vy, vz, -0.2, 0.01);
            field.add(newParticle);
        }
        return super.update(field, particle, start, end);
    }
}
exports.characterBehavior = characterBehavior;
class TextBehavior extends particle_1.Behavior {
    constructor(x, y, z, behavior) {
        super();
        this.x = x;
        this.y = y;
        this.z = z;
        this.behavior = behavior;
    }
    update(field, particle, start, end) {
        const { textPositions, center } = (0, text_1.generateTextPositions)();
        const targetCenter = particle.position;
        const translation = math.vec3.create();
        // offset to the center
        const offset = math.vec3.fromValues(this.x, this.y, this.z);
        math.vec3.subtract(translation, targetCenter, center);
        for (let i = 0; i < textPositions.length; i++) {
            let vy = 1 - Math.random() * 2;
            let vx = Math.random();
            let vz = 1 - Math.random() * 2;
            let newParticle = new particle_1.Particle(this.behavior);
            let newPosition = math.vec3.clone(textPositions[i]);
            // 偏移到中心点
            math.vec3.add(newPosition, newPosition, translation);
            // 偏移到指定位置
            math.vec3.add(newPosition, newPosition, offset);
            newParticle.setProperties(newPosition, particle.rotation, particle.speed, particle.hue, vx, vy, vz, -0.2, 0.01);
            field.add(newParticle);
        }
        return super.update(field, particle, start, end);
    }
}
exports.TextBehavior = TextBehavior;
class BloomVoiceBehavior extends particle_1.Behavior {
    constructor(behavior) {
        super();
        this.behavior = behavior;
    }
    update(field, particle, start, end) {
        soundHandler.PlayBoom(0.3);
        return super.update(field, particle, start, end);
    }
}
exports.BloomVoiceBehavior = BloomVoiceBehavior;
// CrackleVoiceBehavior
class CrackleVoiceBehavior extends particle_1.Behavior {
    constructor(behavior) {
        super();
        this.behavior = behavior;
    }
    update(field, particle, start, end) {
        soundHandler.PlayCrackle(0.3);
        return super.update(field, particle, start, end);
    }
}
exports.CrackleVoiceBehavior = CrackleVoiceBehavior;
class FlairBehavior extends particle_1.Behavior {
    constructor(count, behavior) {
        super();
        this.count = count;
        this.behavior = behavior;
    }
    update(field, particle, start, end) {
        let size = 300;
        let offset = 2 / size;
        let inc = Math.PI * (3.0 - Math.sqrt(5.0));
        // let speed = 2 + Math.random() * 2;
        let speed = 2 + Math.random() * 2;
        let grav = -0.1 - Math.random() * 2;
        for (let i = 0; i < this.count; i++) {
            let newParticle = new particle_1.Particle(this.behavior);
            let vy = Math.abs(((i * offset) - 1) + (offset / 2));
            let r = Math.sqrt(1 - Math.pow(vy, 2));
            let phi = ((i + 1.0) % size) * inc;
            let vx = Math.cos(phi) * r;
            let vz = Math.sin(phi) * r;
            vx *= speed;
            vy *= speed;
            vz *= speed;
            newParticle.setProperties(particle.position, particle.rotation, particle.speed, particle.hue, vx, vy, vz, grav);
            field.add(newParticle);
        }
        return super.update(field, particle, start, end);
    }
}
exports.FlairBehavior = FlairBehavior;
class FireworksBehavior extends particle_1.Behavior {
    constructor(count, behavior) {
        super();
        this.count = count;
        this.behavior = behavior;
    }
    update(field, particle, start, end) {
        for (let i = 0; i < this.count; i++) {
            let vy = 1 - Math.random() * 2;
            let vx = 1 - Math.random() * 2;
            let vz = 1 - Math.random() * 2;
            let newParticle = new particle_1.Particle(this.behavior);
            newParticle.setProperties(particle.position, particle.rotation, particle.speed, particle.hue, vx, vy, vz);
            field.add(newParticle);
        }
        return super.update(field, particle, start, end);
    }
}
exports.FireworksBehavior = FireworksBehavior;
class ContinuousBehavior extends particle_1.Behavior {
    constructor() {
        super();
        // Defaults to complete all provided behaviors
        this.lifespan = Infinity;
    }
    update(field, particle, start, end) {
        if (start == 0) {
            this.offset = 0;
            this.current = this.getNext(true);
        }
        const term = Math.min(this.lifespan, end);
        for (let i = 0; i < 100 && this.current != null; ++i) {
            // There is no more time for this update call
            if (start >= term)
                break;
            const t = this.current.update(field, particle, start - this.offset, term - this.offset);
            // `current` execution continues to next update call
            if (t < 0)
                break;
            start = term - t;
            this.offset = start;
            this.current = this.getNext(false);
        }
        return this.current ? super.update(field, particle, start, end) : end - start;
    }
}
class LoopBehavior extends ContinuousBehavior {
    constructor(body) {
        super();
        this.body = body;
    }
    getNext(first) {
        return this.body;
    }
}
exports.LoopBehavior = LoopBehavior;
class RepeatBehavior extends ContinuousBehavior {
    constructor(body, limit) {
        super();
        this.body = body;
        this.limit = limit;
    }
    getNext(first) {
        this.count = first ? 0 : this.count + 1;
        return this.count < this.limit ? this.body : null;
    }
}
exports.RepeatBehavior = RepeatBehavior;
class SequentialBehavior extends ContinuousBehavior {
    constructor(behaviors) {
        super();
        this.behaviors = behaviors;
    }
    getNext(first) {
        this.index = first ? 0 : this.index + 1;
        return this.index < this.behaviors.length ? this.behaviors[this.index] : null;
    }
}
exports.SequentialBehavior = SequentialBehavior;
class ParallelBehavior extends particle_1.Behavior {
    constructor(behaviors) {
        super();
        this.jobs = behaviors.map(behavior => ({ behavior, remainingTime: -1 }));
    }
    update(field, particle, start, end) {
        for (const j of this.jobs) {
            if (start == 0)
                j.remainingTime = -1;
            if (j.remainingTime < 0)
                j.remainingTime = j.behavior.update(field, particle, start, end);
        }
        return this.jobs.reduce((a, b) => Math.min(a, b.remainingTime), super.update(field, particle, start, end));
    }
}
exports.ParallelBehavior = ParallelBehavior;
