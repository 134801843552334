"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Viewer = exports.CameraController = exports.Camera = exports.Renderer = exports.Scene = void 0;
require("./three-plugins/install");
const scene_1 = require("./scene");
Object.defineProperty(exports, "Scene", { enumerable: true, get: function () { return scene_1.Scene; } });
const renderer_1 = require("./renderer");
Object.defineProperty(exports, "Renderer", { enumerable: true, get: function () { return renderer_1.Renderer; } });
const camera_1 = require("./camera");
Object.defineProperty(exports, "Camera", { enumerable: true, get: function () { return camera_1.Camera; } });
Object.defineProperty(exports, "CameraController", { enumerable: true, get: function () { return camera_1.CameraController; } });
class Viewer {
    constructor() {
        this.camera = new camera_1.Camera(70, 1, 1, 1000, { x: 0, y: 0, o: 0, d: 300 });
        this.scene = new scene_1.Scene(this.camera);
        this.renderer = new renderer_1.Renderer(this.scene, this.camera);
    }
    setTrackpoint(container) {
        const controller = new camera_1.CameraController(this.camera);
        controller.bind(container);
        return () => controller.unbind(container);
    }
    setSize(width, height) {
        this.scene.setSize(width, height);
        this.camera.setSize(width, height);
        this.renderer.setSize(width, height);
    }
    update() {
        this.scene.update();
        this.camera.update();
        this.renderer.render(this.scene, this.camera);
    }
}
exports.Viewer = Viewer;
