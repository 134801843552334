"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.block = exports.program = exports.stmt = exports.random = exports.each = exports.list = exports.sym = exports.num = exports.expr = exports.parseExact = exports.amb = exports.amb1 = exports.lex = exports.lineComment = exports.comment = exports.newlines = exports.spaces = exports.ParseError = void 0;
const dsl = require("./dsl");
const p = require("./aux/parser-combinator");
exports.ParseError = p.ParseError;
// spaces <- (' ' / '\t')+
// newlines <- ('\r' / '\n')+
// comment <- '/*' (!'*/' any)* '*/'
// lineComment <- '//' (!newlines any)*
exports.spaces = p.pattern(`[ \t]+`);
exports.newlines = p.pattern(`[\r\n]+`);
exports.comment = p.pattern(`/\\*[\\s\\S]*?\\*/`);
exports.lineComment = p.pattern(`//.*`);
function lex(q, lineSensitive = false) {
    return p.map(p.sequence(q, lineSensitive ? exports.amb1 : exports.amb), ([v, _]) => v);
}
exports.lex = lex;
exports.amb1 = p.many(p.choice(exports.spaces, exports.comment));
exports.amb = p.many(p.choice(exports.spaces, exports.comment, exports.newlines, exports.lineComment));
function parseExact(q, text) {
    const input = p.input(text);
    const [_1, result, _2] = p.sequence(exports.amb, lex(q), p.eof)(input);
    return result;
}
exports.parseExact = parseExact;
// expr <- num / sym / list / each / random / block
exports.expr = p.lazy(() => p.choice(exports.num, exports.sym, exports.list, exports.each, exports.random, exports.block));
// num <- '-'? (digit* '.' digit+ / digit+) (('e' / 'E') ('+' / '-')? digit+)?
// digit <- '0' / .. / '9'
exports.num = p.map(p.pattern(`-?(?:\\d*\\.\\d+|\\d+)(?:[eE][+-]?\\d+)?`), s => new dsl.Number(parseFloat(s[0])));
// sym <- .. # omitted
exports.sym = p.map(p.pattern(`[!?\$@a-zA-Z_][-+*/%^!?\$@a-zA-Z0-9_]*`), s => new dsl.Symbol(s[0]));
// list <- '(' expr* ')'
exports.list = p.map(p.sequence(lex(p.string('(')), p.many(lex(exports.expr)), p.string(')')), ([_1, elements, _2]) => new dsl.List(elements));
// each <- '[' (expr '..' expr / expr+)? ']'
exports.each = p.map(p.sequence(lex(p.string('[')), meta(dsl.Symbol.eachChoice, dsl.Symbol.eachRange, dsl.Symbol.eachAngle), p.string(']')), ([_1, v, _2]) => v);
// random <- '<' (expr '..' expr / expr+)? '>'
exports.random = p.map(p.sequence(lex(p.string('<')), meta(dsl.Symbol.randomChoice, dsl.Symbol.randomRange, dsl.Symbol.randomAngle), p.string('>')), ([_1, v, _2]) => v);
function meta(choice, range, angle) {
    return p.choice(p.bind(lex(exports.expr), head => p.choice(p.map(p.sequence(lex(p.string('..')), lex(exports.expr)), ([_, last]) => new dsl.List([range, head, last])), p.map(p.many(lex(exports.expr)), tail => new dsl.List([choice, head, ...tail])))), p.pure(angle));
}
// stmt <- expr+ ';'* # no newline
exports.stmt = p.map(p.sequence(p.some(lex(exports.expr, true)), p.many(lex(p.string(';')))), ([ls, _]) => (ls.length == 1 ? ls[0] : new dsl.List(ls)));
// program <- stmt*
exports.program = p.many(lex(exports.stmt));
// block <- '{' program ('|' program)* '}'
exports.block = p.map(p.sequence(lex(p.string('{')), exports.program, p.many(p.sequence(lex(p.string('|')), exports.program)), p.string('}')), ([_1, a, bs, _2]) => new dsl.List([dsl.Symbol.block, new dsl.List(a), ...bs.map(([_, b]) => new dsl.List(b))]));
