"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSimulator = exports.RunSimulator = exports.Simulator = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const simulator_1 = require("../../simulator");
Object.defineProperty(exports, "Simulator", { enumerable: true, get: function () { return simulator_1.Simulator; } });
const Context = (0, preact_1.createContext)(undefined);
const RunSimulator = props => {
    return (0, preact_1.h)(Context.Provider, { value: props.simulator }, props.children);
};
exports.RunSimulator = RunSimulator;
const useSimulator = () => (0, hooks_1.useContext)(Context);
exports.useSimulator = useSimulator;
