"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Screen = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const components_1 = require("./components");
const viewer_1 = require("./effects/viewer");
const Screen = props => {
    const viewer = (0, viewer_1.useViewer)();
    const container = (0, hooks_1.useRef)(null);
    (0, hooks_1.useEffect)(() => {
        return viewer.setTrackpoint(container.current);
    }, [viewer]);
    (0, hooks_1.useEffect)(() => {
        const propagateSize = () => {
            const width = container.current.clientWidth;
            const height = container.current.clientHeight;
            viewer.setSize(width, height);
        };
        window.addEventListener('resize', propagateSize);
        propagateSize();
        return () => window.removeEventListener('resize', propagateSize);
    }, [viewer, container]);
    return ((0, preact_1.h)(components_1.Mount, { dom: viewer.renderer.domElement, className: (0, aphrodite_1.css)(styles.container), containerRef: container }));
};
exports.Screen = Screen;
const styles = aphrodite_1.StyleSheet.create({
    container: {
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
});
