"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.map = exports.some = exports.many = exports.choice = exports.sequence = exports.eof = exports.pattern = exports.string = exports.lazy = exports.fail = exports.bind = exports.pure = exports.ParseError = exports.input = void 0;
function input(head) {
    return { line: 1, col: 1, loc: 0, head };
}
exports.input = input;
function eat(input, length) {
    let ate = input.head.substring(0, length);
    input.head = input.head.substring(length);
    input.loc += length;
    let index = 0;
    while ((index = ate.indexOf('\n')) != -1) {
        input.line += 1;
        input.col = 1;
        ate = ate.substring(index + 1);
    }
    input.col += ate.length;
}
class ParseError extends Error {
    constructor(expect, input) {
        super(`Expected ${expect} at ${input.line}:${input.col}`);
        this.expect = expect;
        this.input = input;
    }
}
exports.ParseError = ParseError;
function pure(result) {
    return _ => result;
}
exports.pure = pure;
function bind(p, m) {
    return input => {
        const v = p(input);
        return m(v)(input);
    };
}
exports.bind = bind;
function fail(e) {
    return input => {
        throw new ParseError(e, input);
    };
}
exports.fail = fail;
function lazy(f) {
    let p;
    return input => {
        if (!p)
            p = f();
        return p(input);
    };
}
exports.lazy = lazy;
function string(s) {
    return input => {
        if (!input.head.startsWith(s))
            throw new ParseError(`"${s}"`, input);
        eat(input, s.length);
        return s;
    };
}
exports.string = string;
function pattern(pat) {
    const re = new RegExp('^' + pat);
    return input => {
        const m = input.head.match(re);
        if (!m)
            throw new ParseError(pat, input);
        eat(input, m[0].length);
        return m;
    };
}
exports.pattern = pattern;
const eof = input => {
    if (input.head.length != 0)
        throw new ParseError('eof', input);
};
exports.eof = eof;
function sequence(...ps) {
    return input => ps.map((p) => p(input));
}
exports.sequence = sequence;
function choice(...ps) {
    return input => {
        const loc = input.loc;
        const expects = [];
        for (const p of ps) {
            try {
                return p(input);
            }
            catch (e) {
                if (input.loc != loc)
                    throw e;
                if (!(e instanceof ParseError))
                    throw e;
                expects.push(e.expect);
            }
        }
        throw new ParseError(expects.join(' or '), input);
    };
}
exports.choice = choice;
function many(p) {
    return input => {
        const rs = [];
        while (true) {
            const loc = input.loc;
            try {
                rs.push(p(input));
            }
            catch (e) {
                if (input.loc != loc)
                    throw e;
                if (!(e instanceof ParseError))
                    throw e;
                return rs;
            }
        }
    };
}
exports.many = many;
function some(p) {
    return map(sequence(p, many(p)), ([a, bs]) => [a, ...bs]);
}
exports.some = some;
function map(p, f) {
    return input => f(p(input));
}
exports.map = map;
