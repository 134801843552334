"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const axios_1 = require("axios");
const axiosInstance = axios_1.default.create({
    baseURL: 'https://www.lightline.space',
    withCredentials: false, // Include credentials for cross-origin requests if needed
});
axiosInstance.interceptors.response.use(response => response, error => {
    console.error('Error in Axios request:', error);
    return Promise.reject(error);
});
exports.default = axiosInstance;
