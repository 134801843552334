"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStats = exports.RunStats = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const Context = (0, preact_1.createContext)(undefined);
const RunStats = props => ((0, preact_1.h)(Context.Provider, { value: props.stats }, props.children));
exports.RunStats = RunStats;
const useStats = () => (0, hooks_1.useContext)(Context);
exports.useStats = useStats;
