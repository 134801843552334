"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.numberString = exports.Slider = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const hooks_2 = require("../hooks");
const Slider = props => {
    const { range: [min, max, step], disabled, value, onChange, style, children, } = props;
    const containerRef = (0, hooks_1.useRef)(null);
    const [sliderHover, sliderActive] = (0, hooks_2.useMouseDragEvent)(containerRef, (ev) => {
        if (disabled)
            return;
        const { left, width } = containerRef.current.getBoundingClientRect();
        const r = Math.min(1, Math.max(0, (ev.clientX - left) / width));
        onChange(min + step * Math.round((r * (max - min)) / step));
    }, [min, max, step, disabled, onChange]);
    (0, hooks_2.useMouseWheelEvent)(containerRef, (ev) => {
        ev.preventDefault();
        if (disabled)
            return;
        onChange(Math.min(max, Math.max(min, value + (ev.deltaY > 0 ? -step : step))));
    }, [value, min, max, step, disabled, onChange]);
    const width = (0, hooks_1.useMemo)(() => ((value - min) / (max - min)) * 100 + '%', [value, min, max]);
    const number = (0, hooks_1.useMemo)(() => numberString(value, step), [value, step]);
    return ((0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.slider, disabled ? styles.sliderDisabled : styles.sliderEnabled), style: style, ref: containerRef },
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.sliderBody, !disabled && sliderHover && styles.sliderBodyHover, !disabled && sliderActive && styles.sliderBodyActive), style: { width } }),
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.sliderSurface) },
            children,
            (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.sliderNumber) }, number))));
};
exports.Slider = Slider;
function numberString(value, step) {
    let decimal = 0;
    while (step < 1) {
        decimal += 1;
        step *= 10;
    }
    let s = value < 0 ? '-' : '';
    let t = String(Math.round(Math.abs(value) * Math.pow(10, decimal)));
    let u = '';
    for (; decimal > 0; --decimal) {
        if (t.length > 1) {
            u = t.substr(t.length - 1) + u;
            t = t.substr(0, t.length - 1);
        }
        else {
            u = t + u;
            t = '0';
        }
    }
    return s + t + (u == '' ? '' : '.' + u);
}
exports.numberString = numberString;
const styles = aphrodite_1.StyleSheet.create({
    slider: {
        position: 'relative',
        cursor: 'ew-resize',
        padding: '2px 4px',
        transition: '0.2s',
        border: '1px solid rgba(255, 255, 255, 0)',
    },
    sliderEnabled: {
        color: '#aaa',
        ':hover': {
            color: '#fff',
            background: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'rgba(255, 255, 255, 0.3)',
        },
        ':active': {
            color: '#fff',
            background: 'rgba(255, 255, 255, 0.1)',
            borderColor: 'rgba(255, 255, 255, 0.7)',
        },
    },
    sliderDisabled: {
        color: 'rgba(255, 255, 255, 0.1)',
    },
    sliderBody: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        background: 'rgba(255, 255, 255, 0.1)',
        transition: '0.2s',
    },
    sliderBodyHover: {
        background: 'rgba(255, 255, 255, 0.3)',
        transitionProperty: 'background',
    },
    sliderBodyActive: {
        background: 'rgba(255, 255, 255, 0.7)',
        transitionProperty: 'background',
    },
    sliderSurface: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sliderNumber: {
        margin: '0 4px 0 12px',
    },
});
