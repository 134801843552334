"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CameraController = exports.Camera = void 0;
const THREE = require("three");
class Camera extends THREE.PerspectiveCamera {
    constructor(fov, aspect, near, far, initialPosition) {
        super(fov, aspect, near, far);
        this.delayFactor = 0.95;
        this.targetPosition = Object.assign({}, initialPosition);
        this.currentPosition = Object.assign({}, initialPosition);
    }
    setSize(width, height) {
        this.aspect = width / height;
        this.updateProjectionMatrix();
    }
    update() {
        this.targetPosition.y = THREE.MathUtils.clamp(this.targetPosition.y, -90, 90);
        this.targetPosition.d = Math.max(this.targetPosition.d, 10);
        this.currentPosition.x =
            this.currentPosition.x * this.delayFactor + this.targetPosition.x * (1 - this.delayFactor);
        this.currentPosition.y =
            this.currentPosition.y * this.delayFactor + this.targetPosition.y * (1 - this.delayFactor);
        this.currentPosition.o =
            this.currentPosition.o * this.delayFactor + this.targetPosition.o * (1 - this.delayFactor);
        this.currentPosition.d =
            this.currentPosition.d * this.delayFactor + this.targetPosition.d * (1 - this.delayFactor);
        const lat = THREE.MathUtils.clamp(this.currentPosition.y, -90, 90);
        const phi = THREE.MathUtils.degToRad(90 - lat);
        const theta = THREE.MathUtils.degToRad(this.currentPosition.x);
        this.position.set(this.currentPosition.d * Math.sin(phi) * Math.cos(theta), this.currentPosition.d * Math.cos(phi) + this.currentPosition.o, this.currentPosition.d * Math.sin(phi) * Math.sin(theta));
        this.lookAt(new THREE.Vector3(0, this.currentPosition.o, 0));
    }
}
exports.Camera = Camera;
class CameraController {
    constructor(_camera) {
        this._camera = _camera;
        this._isDragging = false;
        this.lastDragPos = { x: 0, y: 0 };
        this.activeTocuhes = [];
        this.onMouseDown = (ev) => {
            if (ev.button != 0)
                return;
            ev.preventDefault();
            this.handleDragStart(ev.clientX, ev.clientY);
        };
        this.onMouseMove = (ev) => {
            this.handleDragMove(ev.clientX, ev.clientY);
        };
        this.onMouseUp = (ev) => {
            if (ev.button != 0)
                return;
            this.handleDragEnd();
        };
        this.onWheel = (ev) => {
            this.handleZoom(ev.deltaY < 0 ? -10 : 10);
        };
        this.onTouchStart = (ev) => {
            ev.preventDefault();
            for (const t of iterableTouches(ev.changedTouches))
                this.activeTocuhes.push(t);
            if (this.activeTocuhes.length == 1) {
                const touch = this.activeTocuhes[0];
                this.handleDragStart(touch.clientX, touch.clientY);
            }
        };
        this.onTouchMove = (ev) => {
            const movedTouches = {};
            for (const t of iterableTouches(ev.changedTouches))
                movedTouches[t.identifier] = t;
            if (this.activeTocuhes.length == 1) {
                const touch = movedTouches[this.activeTocuhes[0].identifier];
                if (touch)
                    this.handleDragMove(touch.clientX, touch.clientY);
            }
            else {
                const prevDistance = distanceBetweenTouches(this.activeTocuhes[0], this.activeTocuhes[1]);
                for (let i of [0, 1]) {
                    const newTouch = movedTouches[this.activeTocuhes[i].identifier];
                    if (newTouch)
                        this.activeTocuhes[i] = newTouch;
                }
                const currDistance = distanceBetweenTouches(this.activeTocuhes[0], this.activeTocuhes[1]);
                this.handleZoom(prevDistance - currDistance);
            }
        };
        this.onTouchEnd = (ev) => {
            for (const t of iterableTouches(ev.changedTouches)) {
                for (let i = 0; i < this.activeTocuhes.length; ++i) {
                    if (this.activeTocuhes[i].identifier != t.identifier)
                        continue;
                    this.activeTocuhes.splice(i, 1);
                    this.handleDragEnd();
                    break;
                }
            }
        };
    }
    get isDragging() {
        return this._isDragging;
    }
    bind(container) {
        container.addEventListener('mousedown', this.onMouseDown, false);
        document.addEventListener('mousemove', this.onMouseMove, false);
        document.addEventListener('mouseup', this.onMouseUp, false);
        container.addEventListener('wheel', this.onWheel, false);
        container.addEventListener('touchstart', this.onTouchStart, false);
        container.addEventListener('touchmove', this.onTouchMove, false);
        document.addEventListener('touchend', this.onTouchEnd, false);
    }
    unbind(container) {
        container.removeEventListener('mousedown', this.onMouseDown, false);
        document.removeEventListener('mousemove', this.onMouseMove, false);
        document.removeEventListener('mouseup', this.onMouseUp, false);
        container.removeEventListener('wheel', this.onWheel, false);
        container.removeEventListener('touchstart', this.onTouchStart, false);
        container.removeEventListener('touchmove', this.onTouchMove, false);
        document.removeEventListener('touchend', this.onTouchEnd, false);
    }
    handleDragStart(x, y) {
        this.lastDragPos.x = x;
        this.lastDragPos.y = y;
        this._isDragging = true;
    }
    handleDragMove(x, y) {
        if (!this._isDragging)
            return;
        this._camera.targetPosition.x += (x - this.lastDragPos.x) * 0.35;
        this._camera.targetPosition.y += (y - this.lastDragPos.y) * 0.35;
        this.lastDragPos.x = x;
        this.lastDragPos.y = y;
    }
    handleDragEnd() {
        this._isDragging = false;
    }
    handleZoom(distance) {
        this._camera.targetPosition.d += distance;
    }
}
exports.CameraController = CameraController;
function iterableTouches(t) {
    return Array.prototype.slice.call(t);
}
function distanceBetweenTouches(l, r) {
    return Math.sqrt(Math.pow((l.clientX - r.clientX), 2) + Math.pow((l.clientY - r.clientY), 2));
}
