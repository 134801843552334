"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoundHandler = void 0;
const THREE = require("three");
class SoundHandler {
    constructor() {
        this.listener = new THREE.AudioListener();
        this.audioLoader = new THREE.AudioLoader();
        this.crackle = [];
        this.boom = [];
        this.background = [];
        this.boomCounter = 0;
        this.Init();
    }
    //
    Init() {
        const self = this;
        this.audioLoader.load('sounds/boom.mp3', function (buffer) {
            self.boom.push(buffer);
        });
        this.audioLoader.load('sounds/crackle.mp3', function (buffer) {
            self.crackle.push(buffer);
        });
        this.audioLoader.load('sounds/heaven.mp3', function (buffer) {
            self.background.push(buffer);
        });
    }
    PlayCrackle(volume) {
        const buffer = this.crackle[0];
        this.Play(buffer, volume);
    }
    PlayBoom(volume) {
        this.boomCounter++;
        if (this.boomCounter % 2 === 0) {
            const buffer = this.boom[0];
            this.Play(buffer, volume);
        }
    }
    PlayBackground(volume) {
        const buffer = this.background[0];
        this.Play(buffer, volume, true);
    }
    Play(buffer, volume, loop = false) {
        const sound = new THREE.Audio(this.listener);
        sound.setBuffer(buffer);
        sound.setLoop(loop);
        sound.setVolume(volume);
        sound.play();
    }
}
exports.SoundHandler = SoundHandler;
