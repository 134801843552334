"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransitionGraph = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const slider_1 = require("./slider");
const store_1 = require("../effects/store");
const TransitionGraph = props => {
    const { disabled, value, onChange, children } = props;
    const { init, center, exponent } = value;
    const initChange = (0, hooks_1.useCallback)((init) => onChange({ init, center, exponent }), [
        center,
        exponent,
        onChange,
    ]);
    const centerChange = (0, hooks_1.useCallback)((center) => onChange({ init, center, exponent }), [
        init,
        exponent,
        onChange,
    ]);
    const exponentChange = (0, hooks_1.useCallback)((exponent) => onChange({ init, center, exponent }), [
        init,
        center,
        onChange,
    ]);
    const f = (0, store_1.compileTransition)(value);
    const command = 'M 0 100 ' +
        Array(101)
            .fill(0)
            .map((_, i) => `L ${i} ${100 - Math.floor(f(i / 100) * 100)}`)
            .join(' ');
    return ((0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.container, disabled ? styles.containerDisabled : styles.containerEnabled) },
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.header) }, children),
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.body) },
            (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.parameters) },
                (0, preact_1.h)(slider_1.Slider, { disabled: disabled, range: [0, 1, 0.02], value: init, onChange: initChange }, "init"),
                (0, preact_1.h)(slider_1.Slider, { disabled: disabled, range: [0, 1, 0.02], value: center, onChange: centerChange }, "center"),
                (0, preact_1.h)(slider_1.Slider, { disabled: disabled, range: [-3, 3, 0.1], value: exponent, onChange: exponentChange }, "exponent")),
            (0, preact_1.h)("svg", { width: "1", height: "1", viewBox: "0 0 100 100", className: (0, aphrodite_1.css)(styles.preview, !disabled && styles.previewEnabled) },
                (0, preact_1.h)("path", { d: command })))));
};
exports.TransitionGraph = TransitionGraph;
const styles = aphrodite_1.StyleSheet.create({
    container: {
        transition: '0.2s',
        border: '1px solid rgba(255, 255, 255, 0)',
    },
    containerEnabled: {
        color: '#aaa',
        ':hover': {
            color: '#fff',
            borderColor: 'rgba(255, 255, 255, 0.3)',
        },
    },
    containerDisabled: {
        color: 'rgba(255, 255, 255, 0.1)',
    },
    header: {
        padding: '2px 4px',
    },
    body: {
        display: 'flex',
    },
    parameters: {
        margin: '2px',
        fontSize: '10px',
        flex: '3 1',
    },
    preview: {
        margin: '2px',
        flex: '1 1',
        height: 'auto',
        stroke: 'rgba(255, 255, 255, 0.1)',
        strokeWidth: '3px',
        fill: 'rgba(255, 255, 255, 0.05)',
    },
    previewEnabled: {
        stroke: 'rgba(255, 255, 255, 0.4)',
        fill: 'rgba(255, 255, 255, 0.1)',
    },
});
