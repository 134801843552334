"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Code = void 0;
const dsl = require("./dsl");
class Code {
    constructor() {
        this.programs = [[]];
        this.pointer = 0;
    }
    put(...line) {
        const expr = dsl.AST.fromPlain(line.length == 1 ? line[0] : line);
        return this.putAST(expr);
    }
    putCode(code) {
        const program = code.toProgram();
        return this.putProgram(program);
    }
    putEachChoice(...codes) {
        if (codes.length == 1)
            return this.putCode(codes[0]);
        return this.put(Code.eachChoice(...codes.map(c => c.toAST())));
    }
    putRandomChoice(...codes) {
        if (codes.length == 1)
            return this.putCode(codes[0]);
        return this.put(Code.randomChoice(...codes.map(c => c.toAST())));
    }
    putAST(expr) {
        if (this.current) {
            this.current.putAST(expr);
        }
        else {
            this.programs[this.pointer].push(expr);
        }
        return this;
    }
    putProgram(program) {
        if (this.current) {
            this.current.putProgram(program);
        }
        else {
            this.programs[this.pointer].push(...program);
        }
        return this;
    }
    toProgram() {
        if (this.current)
            throw new Error('begin block is incomplete');
        const programs = this.programs.filter(program => program.length != 0);
        if (programs.length == 0)
            return [];
        if (programs.length == 1)
            return programs[0];
        return [new dsl.List([dsl.Symbol.block, ...programs.map(program => new dsl.List(program))])];
    }
    toAST() {
        const program = this.toProgram();
        if (program.length == 1)
            return program[0];
        return new dsl.List([dsl.Symbol.block, new dsl.List(program)]);
    }
    tap(f) {
        f(this);
        return this;
    }
    join() {
        if (this.current) {
            this.current.join();
        }
        else {
            this.programs.push([]);
            this.pointer++;
        }
        return this;
    }
    begin() {
        if (this.current) {
            this.current.begin();
        }
        else {
            this.current = new Code();
        }
        return this;
    }
    end(parent) {
        if (this.current) {
            this.current.end(this);
        }
        else {
            delete parent.current;
            parent.putCode(this);
        }
        return this;
    }
    static eachChoice(...values) {
        if (values.length == 1)
            return values[0];
        return [dsl.Symbol.eachChoice, ...values];
    }
    static eachRange(a, b) {
        return [dsl.Symbol.eachRange, a, b];
    }
    static randomChoice(...values) {
        if (values.length == 1)
            return values[0];
        return [dsl.Symbol.randomChoice, ...values];
    }
    static randomRange(a, b) {
        return [dsl.Symbol.randomRange, a, b];
    }
}
exports.Code = Code;
Code.eachAngle = dsl.Symbol.eachAngle;
Code.randomAngle = dsl.Symbol.randomAngle;
