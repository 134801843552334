"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getComments = exports.getTimes = exports.generateOrder = exports.fetchData = void 0;
const axiosConfig_1 = require("./axiosConfig");
const fetchData = (id) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosConfig_1.default.get(`/api/checkId`, { params: { id } });
        debugger;
        return response.data;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
});
exports.fetchData = fetchData;
const generateOrder = (id, comments) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosConfig_1.default.post(`/api/generateOrder`, { id, comments }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
});
exports.generateOrder = generateOrder;
const getTimes = (id) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosConfig_1.default.get(`/api/getTimes`, { params: { id } });
        return response.data;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
});
exports.getTimes = getTimes;
// getComments
const getComments = (id) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axiosConfig_1.default.get(`/api/getComments`, { params: { id } });
        return response.data;
    }
    catch (error) {
        console.error('Error fetching data:', error);
        return null;
    }
});
exports.getComments = getComments;
