"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RandomAngleUnit = exports.RandomRangeUnit = exports.RandomChoiceUnit = exports.EachAngleUnit = exports.EachRangeUnit = exports.EachChoiceUnit = exports.BlockUnit = exports.CrackleVoiceUnit = exports.BloomVoiceUnit = exports.TextUnit = exports.CharacterUnit = exports.FireworksUnit = exports.FlairUnit = exports.EmitUnit = exports.RepeatUnit = exports.LoopUnit = exports.RotateUnit = exports.FireworksExplodeUnit = exports.TextExplodeUnit = exports.TranslateUnit = exports.AddHueUnit = exports.SetHueUnit = exports.MultiplyOpacityUnit = exports.AddOpacityUnit = exports.SetOpacityUnit = exports.MultiplySpeedUnit = exports.AddSpeedUnit = exports.SetSpeedUnit = exports.CloseUnit = exports.NopUnit = exports.EasingUnit = exports.UnitConstructor = exports.Unit = exports.Compiler = exports.CompileError = void 0;
const behavior = require("./particle-behavior");
const dsl = require("./dsl");
const units_1 = require("./units");
class CompileError extends Error {
    constructor(message) {
        super(message);
    }
}
exports.CompileError = CompileError;
class Compiler {
    constructor() {
        (0, units_1.initializeUnits)();
    }
    compileProgram(program) {
        if (program.length == 1)
            return this.compile(program[0]);
        return this.compile(new dsl.List([dsl.Symbol.block, new dsl.List(program)]));
    }
    compile(expr) {
        return this.getUnit(expr).behavior(this);
    }
    getUnit(expr) {
        return expr.visit({
            number: v => new NumberUnit(v.value),
            symbol: v => {
                const unit = units_1.units.get(v.value);
                if (!unit) {
                    // throw new CompileError(`Unknown identifier "${v.value}"`);
                    return new StringUnit(v.value);
                }
                return unit;
            },
            list: v => {
                if (v.elements.length == 0)
                    return new NilUnit();
                return this.getUnit(v.elements[0]).withArguments(v.elements.slice(1));
            },
        });
    }
}
exports.Compiler = Compiler;
class Unit {
    withArguments(args) {
        throw new CompileError(`${this.constructor.name} takes no arguments`);
    }
    number(env) {
        throw new CompileError(`Expected number but got ${this.constructor.name}`);
    }
    easing(env) {
        throw new CompileError(`Expected easing but got ${this.constructor.name}`);
    }
    behavior(env) {
        throw new CompileError(`Expected behavior but got ${this.constructor.name}`);
    }
    string(env) {
        throw new CompileError(`Expected string but got ${this.constructor.name}`);
    }
}
exports.Unit = Unit;
class UnitConstructor extends Unit {
    constructor(c) {
        super();
        this.c = c;
    }
    withArguments(args) {
        return new this.c(args);
    }
}
exports.UnitConstructor = UnitConstructor;
class ConstructedUnit extends Unit {
    constructor(args) {
        super();
        this.args = args;
    }
    takeArgs(env, length) {
        if (this.args.length != length) {
            throw new CompileError(`${this.constructor.name} takes ${length} arguments but got ${this.args.length}`);
        }
        let i = 0;
        return {
            easing: () => env.getUnit(this.args[i++]).easing(env),
            number: () => env.getUnit(this.args[i++]).number(env),
            behavior: () => env.getUnit(this.args[i++]).behavior(env),
            string: () => env.getUnit(this.args[i++]).string(env),
        };
    }
}
class NilUnit extends Unit {
}
class NumberUnit extends Unit {
    constructor(value) {
        super();
        this.value = value;
    }
    withArguments(args) {
        return new PutLifespanUnit(this, args);
    }
    number(env) {
        const value = this.value;
        return _ => value;
    }
}
class StringUnit extends Unit {
    constructor(value) {
        super();
        this.value = value;
    }
    withArguments(args) {
        return new PutLifespanUnit(this, args);
    }
    string(env) {
        const value = this.value;
        return _ => value;
    }
}
class EasingUnit extends Unit {
    constructor(value) {
        super();
        this.value = value;
    }
    withArguments(args) {
        return new PutEasingUnit(this, args);
    }
    easing(env) {
        const value = this.value;
        return _ => value;
    }
}
exports.EasingUnit = EasingUnit;
class PutLifespanUnit extends ConstructedUnit {
    constructor(unit, args) {
        super(args);
        this.unit = unit;
    }
    behavior(env) {
        const bodyUnit = env.getUnit(this.args.length == 1 ? this.args[0] : new dsl.List(this.args));
        const behaviorGen = bodyUnit.behavior(env);
        const lifespanGen = this.unit.number(env);
        return index => {
            const behavior = behaviorGen(index);
            behavior.lifespan = lifespanGen(index);
            return behavior;
        };
    }
}
class PutEasingUnit extends ConstructedUnit {
    constructor(unit, args) {
        super(args);
        this.unit = unit;
    }
    behavior(env) {
        const bodyUnit = env.getUnit(this.args.length == 1 ? this.args[0] : new dsl.List(this.args));
        const behaviorGen = bodyUnit.behavior(env);
        const easingGen = this.unit.easing(env);
        return index => {
            const behavior = behaviorGen(index);
            behavior.easing = easingGen(index);
            return behavior;
        };
    }
}
class NopUnit extends Unit {
    behavior(env) {
        return _ => new behavior.NopBehavior();
    }
}
exports.NopUnit = NopUnit;
class CloseUnit extends Unit {
    behavior(env) {
        return _ => new behavior.SwitchBehavior(p => (p.closed = true));
    }
}
exports.CloseUnit = CloseUnit;
class SetSpeedUnit extends ConstructedUnit {
    behavior(env) {
        const speedGen = this.takeArgs(env, 1).number();
        return index => new behavior.SetBehavior(speedGen(index), p => p.speed, (p, v) => (p.speed = v));
    }
}
exports.SetSpeedUnit = SetSpeedUnit;
class AddSpeedUnit extends ConstructedUnit {
    behavior(env) {
        const speedGen = this.takeArgs(env, 1).number();
        return index => new behavior.AddBehavior(speedGen(index), (p, v) => (p.speed += v));
    }
}
exports.AddSpeedUnit = AddSpeedUnit;
class MultiplySpeedUnit extends ConstructedUnit {
    behavior(env) {
        const speedGen = this.takeArgs(env, 1).number();
        return index => new behavior.MultiplyBehavior(speedGen(index), (p, s) => (p.speed *= s));
    }
}
exports.MultiplySpeedUnit = MultiplySpeedUnit;
class SetOpacityUnit extends ConstructedUnit {
    behavior(env) {
        const opacityGen = this.takeArgs(env, 1).number();
        return index => new behavior.SetBehavior(opacityGen(index), p => p.opacity, (p, v) => (p.opacity = v));
    }
}
exports.SetOpacityUnit = SetOpacityUnit;
class AddOpacityUnit extends ConstructedUnit {
    behavior(env) {
        const opacityGen = this.takeArgs(env, 1).number();
        return index => new behavior.AddBehavior(opacityGen(index), (p, v) => (p.opacity += v));
    }
}
exports.AddOpacityUnit = AddOpacityUnit;
class MultiplyOpacityUnit extends ConstructedUnit {
    behavior(env) {
        const opacityGen = this.takeArgs(env, 1).number();
        return index => new behavior.MultiplyBehavior(opacityGen(index), (p, s) => (p.opacity *= s));
    }
}
exports.MultiplyOpacityUnit = MultiplyOpacityUnit;
class SetHueUnit extends ConstructedUnit {
    behavior(env) {
        const hueGen = this.takeArgs(env, 1).number();
        return index => new behavior.SetBehavior(hueGen(index), p => p.hue, (p, v) => (p.hue = v));
    }
}
exports.SetHueUnit = SetHueUnit;
class AddHueUnit extends ConstructedUnit {
    behavior(env) {
        const hueGen = this.takeArgs(env, 1).number();
        return index => new behavior.AddBehavior(hueGen(index), (p, v) => (p.hue += v));
    }
}
exports.AddHueUnit = AddHueUnit;
class TranslateUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 3);
        const xGen = args.number();
        const yGen = args.number();
        const zGen = args.number();
        return index => new behavior.TranslateBehavior(xGen(index), yGen(index), zGen(index));
    }
}
exports.TranslateUnit = TranslateUnit;
class TextExplodeUnit extends ConstructedUnit {
    behavior(env) {
        return index => new behavior.TextExplodeBehavior();
    }
}
exports.TextExplodeUnit = TextExplodeUnit;
class FireworksExplodeUnit extends ConstructedUnit {
    behavior(env) {
        return index => new behavior.FireworksExplodeBehavior();
    }
}
exports.FireworksExplodeUnit = FireworksExplodeUnit;
class RotateUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 3);
        const xdegGen = args.number();
        const ydegGen = args.number();
        const zdegGen = args.number();
        return index => new behavior.RotateBehavior(xdegGen(index), ydegGen(index), zdegGen(index));
    }
}
exports.RotateUnit = RotateUnit;
class LoopUnit extends ConstructedUnit {
    behavior(env) {
        const patternGen = this.takeArgs(env, 1).behavior();
        return index => new behavior.LoopBehavior(patternGen(index));
    }
}
exports.LoopUnit = LoopUnit;
class RepeatUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 2);
        const limitGen = args.number();
        const patternGen = args.behavior();
        return index => new behavior.RepeatBehavior(patternGen(index), limitGen(index));
    }
}
exports.RepeatUnit = RepeatUnit;
class EmitUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 4);
        const countGen = args.number();
        const timesGen = args.number();
        const parallelGen = args.number();
        const childPattern = args.behavior();
        return index => {
            const count = countGen(index);
            const times = timesGen(index);
            const parallel = parallelGen(index);
            const max = count * times * parallel;
            return new behavior.EmitBehavior(count, times, parallel, i => childPattern([i, max]));
        };
    }
}
exports.EmitUnit = EmitUnit;
class FlairUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 2);
        const countGen = args.number();
        const childPattern = args.behavior();
        return index => {
            const count = countGen(index);
            return new behavior.FlairBehavior(count, childPattern([0, 1]));
        };
    }
}
exports.FlairUnit = FlairUnit;
class FireworksUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 2);
        const countGen = args.number();
        const childPattern = args.behavior();
        return index => {
            const count = countGen(index);
            return new behavior.FireworksBehavior(count, childPattern([0, 1]));
        };
    }
}
exports.FireworksUnit = FireworksUnit;
class CharacterUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 2);
        const stringGen = args.string();
        const childPattern = args.behavior();
        return index => {
            const s = stringGen(index);
            return new behavior.characterBehavior(s, childPattern([0, 1]));
        };
    }
}
exports.CharacterUnit = CharacterUnit;
class TextUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 4);
        const xGen = args.number();
        const yGen = args.number();
        const zGen = args.number();
        const childPattern = args.behavior();
        return index => {
            const x = xGen(index);
            const y = yGen(index);
            const z = zGen(index);
            return new behavior.TextBehavior(x, y, z, childPattern([0, 1]));
        };
    }
}
exports.TextUnit = TextUnit;
class BloomVoiceUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 1);
        const childPattern = args.behavior();
        return index => {
            return new behavior.BloomVoiceBehavior(childPattern([0, 1]));
        };
    }
}
exports.BloomVoiceUnit = BloomVoiceUnit;
class CrackleVoiceUnit extends ConstructedUnit {
    behavior(env) {
        const args = this.takeArgs(env, 1);
        const childPattern = args.behavior();
        return index => {
            return new behavior.CrackleVoiceBehavior(childPattern([0, 1]));
        };
    }
}
exports.CrackleVoiceUnit = CrackleVoiceUnit;
class BlockUnit extends ConstructedUnit {
    behavior(env) {
        const behaviorGens = this.args.map(arg => {
            if (!(arg instanceof dsl.List)) {
                throw new CompileError(`Each arguments of ${dsl.Symbol.block.value} must be list`);
            }
            return arg.elements.map(e => env.getUnit(e).behavior(env));
        });
        return index => {
            const behaviors = behaviorGens.map(gens => new behavior.SequentialBehavior(gens.map(gen => gen(index))));
            return new behavior.ParallelBehavior(behaviors);
        };
    }
}
exports.BlockUnit = BlockUnit;
class ChoiceUnit extends ConstructedUnit {
    getUnit(env, expr) {
        return env.getUnit(expr);
    }
    gen(env, f) {
        if (this.args.length == 0) {
            throw new CompileError(`${this.constructor.name} takes at least one argumnet`);
        }
        const gens = this.args.map(arg => f(this.getUnit(env, arg)));
        const choiceGen = this.choiceGen(gens.length);
        return index => gens[choiceGen(index)](index);
    }
    withArguments(args) {
        return new ChoiceUnitWithArguments(this.args, this, args);
    }
    number(env) {
        return this.gen(env, unit => unit.number(env));
    }
    easing(env) {
        return this.gen(env, unit => unit.easing(env));
    }
    behavior(env) {
        return this.gen(env, unit => unit.behavior(env));
    }
}
class ChoiceUnitWithArguments extends ChoiceUnit {
    constructor(args, body, unitArgs) {
        super(args);
        this.body = body;
        this.unitArgs = unitArgs;
    }
    choiceGen(size) {
        return this.body.choiceGen(size);
    }
    getUnit(env, expr) {
        return super.getUnit(env, expr).withArguments(this.unitArgs);
    }
}
class RangeUnit extends ConstructedUnit {
    withArguments(args) {
        return new PutLifespanUnit(this, args);
    }
    number(env) {
        const args = this.takeArgs(env, 2);
        const minGen = args.number();
        const maxGen = args.number();
        const rangeGen = this.rangeGen();
        return index => {
            const min = minGen(index);
            const max = maxGen(index);
            const r = rangeGen(index);
            return min * (1 - r) + max * r;
        };
    }
}
class EachChoiceUnit extends ChoiceUnit {
    choiceGen(size) {
        return ([a, _]) => a % size;
    }
}
exports.EachChoiceUnit = EachChoiceUnit;
class EachRangeUnit extends RangeUnit {
    rangeGen() {
        return ([a, b]) => (b <= 1 ? 0.5 : a / (b - 1));
    }
}
exports.EachRangeUnit = EachRangeUnit;
class EachAngleUnit extends Unit {
    number(env) {
        return ([a, b]) => (360 * a) / b + (b == 2 ? 90 : 0);
    }
}
exports.EachAngleUnit = EachAngleUnit;
class RandomChoiceUnit extends ChoiceUnit {
    choiceGen(size) {
        return _ => Math.floor(Math.random() * size);
    }
}
exports.RandomChoiceUnit = RandomChoiceUnit;
class RandomRangeUnit extends RangeUnit {
    rangeGen() {
        return _ => Math.random();
    }
}
exports.RandomRangeUnit = RandomRangeUnit;
class RandomAngleUnit extends Unit {
    number(env) {
        return _ => Math.random() * 360;
    }
}
exports.RandomAngleUnit = RandomAngleUnit;
