"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Window = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const aphrodite_1 = require("aphrodite");
const button_1 = require("./button");
const Window = props => {
    const { children, width, height, top, bottom, left, right } = props;
    const flexDirection = top ? 'column' : 'column-reverse';
    const alignItems = left ? 'flex-start' : 'flex-end';
    const [isOpened, setIsOpened] = (0, hooks_1.useState)(false);
    const handleToggle = (0, hooks_1.useCallback)(() => setIsOpened(isOpened => !isOpened), []);
    return ((0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.window, isOpened && styles.windowOpened), style: {
            flexDirection,
            alignItems,
            top: top || 'auto',
            bottom: bottom || 'auto',
            left: left || 'auto',
            right: right || 'auto',
        } },
        (0, preact_1.h)(button_1.Button, { onClick: handleToggle }, isOpened ? '-' : '+'),
        (0, preact_1.h)("div", { className: (0, aphrodite_1.css)(styles.content, isOpened && styles.contentOpened), style: { width: width || 'auto', height: height || 'auto' } }, children)));
};
exports.Window = Window;
const styles = aphrodite_1.StyleSheet.create({
    window: {
        position: 'absolute',
        display: 'flex',
        border: '1px solid rgba(85, 85, 85, 0)',
        transition: '0.2s',
    },
    windowOpened: {
        borderColor: '#555',
        background: 'rgba(30, 30, 30, 0.8)',
    },
    content: {
        margin: '2px 4px',
        display: 'none',
    },
    contentOpened: {
        display: 'block',
    },
});
