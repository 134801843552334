"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initializeUnits = exports.units = void 0;
const easing_1 = require("./aux/easing");
const dsl_compiler_1 = require("./dsl-compiler");
const dsl = require("./dsl");
exports.units = new Map();
function initializeUnits() {
    exports.units.set('linear', new dsl_compiler_1.EasingUnit(easing_1.Easing.linear));
    exports.units.set('ease-in', new dsl_compiler_1.EasingUnit(easing_1.Easing.easeIn));
    exports.units.set('ease-out', new dsl_compiler_1.EasingUnit(easing_1.Easing.easeOut));
    exports.units.set('ease-in-out', new dsl_compiler_1.EasingUnit(easing_1.Easing.easeInOut));
    exports.units.set('nop', new dsl_compiler_1.NopUnit());
    exports.units.set('close', new dsl_compiler_1.CloseUnit());
    exports.units.set('speed', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.SetSpeedUnit));
    exports.units.set('speed+', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.AddSpeedUnit));
    exports.units.set('speed*', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.MultiplySpeedUnit));
    exports.units.set('opacity', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.SetOpacityUnit));
    exports.units.set('opacity+', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.AddOpacityUnit));
    exports.units.set('opacity*', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.MultiplyOpacityUnit));
    exports.units.set('hue', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.SetHueUnit));
    exports.units.set('hue+', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.AddHueUnit));
    exports.units.set('translate', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.TranslateUnit));
    exports.units.set('rotate', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.RotateUnit));
    exports.units.set('emit', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.EmitUnit));
    exports.units.set('loop', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.LoopUnit));
    exports.units.set('repeat', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.RepeatUnit));
    exports.units.set('flair', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.FlairUnit));
    exports.units.set('fireworks', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.FireworksUnit));
    exports.units.set('fireworksExplode', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.FireworksExplodeUnit));
    exports.units.set('text', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.TextUnit));
    exports.units.set('textExplode', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.TextExplodeUnit));
    exports.units.set('character', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.CharacterUnit));
    exports.units.set('bloomVoice', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.BloomVoiceUnit));
    exports.units.set('crackleVoice', new dsl_compiler_1.UnitConstructor(dsl_compiler_1.CrackleVoiceUnit));
    exports.units.set(dsl.Symbol.block.value, new dsl_compiler_1.UnitConstructor(dsl_compiler_1.BlockUnit));
    exports.units.set(dsl.Symbol.eachChoice.value, new dsl_compiler_1.UnitConstructor(dsl_compiler_1.EachChoiceUnit));
    exports.units.set(dsl.Symbol.eachRange.value, new dsl_compiler_1.UnitConstructor(dsl_compiler_1.EachRangeUnit));
    exports.units.set(dsl.Symbol.eachAngle.value, new dsl_compiler_1.EachAngleUnit());
    exports.units.set(dsl.Symbol.randomChoice.value, new dsl_compiler_1.UnitConstructor(dsl_compiler_1.RandomChoiceUnit));
    exports.units.set(dsl.Symbol.randomRange.value, new dsl_compiler_1.UnitConstructor(dsl_compiler_1.RandomRangeUnit));
    exports.units.set(dsl.Symbol.randomAngle.value, new dsl_compiler_1.RandomAngleUnit());
}
exports.initializeUnits = initializeUnits;
