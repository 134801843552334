"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useViewer = exports.RunViewer = exports.Viewer = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const viewer_1 = require("../../viewer");
Object.defineProperty(exports, "Viewer", { enumerable: true, get: function () { return viewer_1.Viewer; } });
const Context = (0, preact_1.createContext)(undefined);
const RunViewer = props => {
    return (0, preact_1.h)(Context.Provider, { value: props.viewer }, props.children);
};
exports.RunViewer = RunViewer;
const useViewer = () => (0, hooks_1.useContext)(Context);
exports.useViewer = useViewer;
