"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mount = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const Mount = props => {
    const { dom, className, style } = props;
    const container = props.containerRef || (0, hooks_1.useRef)(null);
    (0, hooks_1.useEffect)(() => {
        container.current.appendChild(dom);
        return () => container.current.removeChild(dom);
    }, []);
    return (0, preact_1.h)("div", { className: className || '', style: style || {}, ref: container });
};
exports.Mount = Mount;
