"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Renderer = void 0;
const THREE = require("three");
class Renderer {
    constructor(scene, camera) {
        this.webGL = new THREE.WebGLRenderer({ antialias: false });
        this.webGL.setPixelRatio(window.devicePixelRatio);
        this.passes = {
            render: new THREE.RenderPass(scene, camera),
            bloom: new THREE.UnrealBloomPass(),
            copy: new THREE.ShaderPass(THREE.CopyShader),
            SMAA: new THREE.SMAAPass(2, 2),
        };
        this.passes.copy.renderToScreen = true;
        this.passes.SMAA.renderToScreen = true;
        this.composer = new THREE.EffectComposer(this.webGL);
        this.composer.addPass(this.passes.render);
        this.composer.addPass(this.passes.bloom);
        this.composer.addPass(this.passes.copy);
        this.composer.addPass(this.passes.SMAA);
        this.antialias = false;
        this.bloom = false;
    }
    get domElement() {
        return this.webGL.domElement;
    }
    set antialias(enabled) {
        this.passes.SMAA.enabled = enabled;
        this.passes.copy.enabled = !enabled;
    }
    set bloom(enabled) {
        this.passes.bloom.enabled = enabled;
    }
    set bloomStrength(value) {
        this.passes.bloom.strength = value;
    }
    set bloomRadius(value) {
        this.passes.bloom.radius = value;
    }
    set bloomThreshold(value) {
        this.passes.bloom.threshold = value;
    }
    setSize(width, height) {
        this.webGL.setSize(width, height);
        this.composer.setSize(width, height);
    }
    render(scene, camera) {
        this.webGL.render(scene, camera);
    }
}
exports.Renderer = Renderer;
