"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Explorer = exports.MapStorage = exports.NativeStorage = void 0;
const storage_1 = require("./storage");
Object.defineProperty(exports, "NativeStorage", { enumerable: true, get: function () { return storage_1.NativeStorage; } });
Object.defineProperty(exports, "MapStorage", { enumerable: true, get: function () { return storage_1.MapStorage; } });
class Explorer {
    constructor() {
        this.mounts = [];
    }
    explore() {
        return this.mounts.map(({ path, storage }) => ({ path, writable: storage.writable }));
    }
    storage(path) {
        const mount = this.mounts.find(mount => mount.path == path);
        if (!mount)
            throw new Error(`Cannot read path ${path}`);
        return mount.storage;
    }
    mount(path, storage) {
        if (this.mounts.find(mount => mount.path == path)) {
            throw new Error(`Cannot mount storage at ${path}: path already taken`);
        }
        this.mounts.push({ path, storage });
    }
    read(path, item) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.storage(path).read(item);
        });
    }
    write(path, item, body) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.storage(path).write(item, body);
        });
    }
    delete(path, item) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.storage(path).delete(item);
        });
    }
    items(path) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield this.storage(path).items();
        });
    }
}
exports.Explorer = Explorer;
