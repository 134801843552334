"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeStorage = void 0;
// NOTE: This should be replaced with KV Storage
class NativeStorage {
    constructor(storage, prefix = '') {
        this.storage = storage;
        this.prefix = prefix;
        this.writable = true;
    }
    read(item) {
        return __awaiter(this, void 0, void 0, function* () {
            const result = this.storage.getItem(this.prefix + item);
            if (!result)
                throw new Error(`No item named ${item}`);
            return result;
        });
    }
    write(item, body) {
        return __awaiter(this, void 0, void 0, function* () {
            this.storage.setItem(this.prefix + item, body);
        });
    }
    delete(item) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.storage.getItem(this.prefix + item) == null)
                return false;
            this.storage.removeItem(this.prefix + item);
            return true;
        });
    }
    items() {
        return __awaiter(this, void 0, void 0, function* () {
            const result = [];
            for (let i = 0; i < this.storage.length; ++i) {
                const item = this.storage.key(i);
                if (item.startsWith(this.prefix))
                    result.push(item.substring(this.prefix.length));
            }
            return result;
        });
    }
}
exports.NativeStorage = NativeStorage;
