"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useExplorer = exports.RunExplorer = void 0;
const preact_1 = require("preact");
const hooks_1 = require("preact/hooks");
const Context = (0, preact_1.createContext)(undefined);
const RunExplorer = props => ((0, preact_1.h)(Context.Provider, { value: props.explorer }, props.children));
exports.RunExplorer = RunExplorer;
const useExplorer = () => (0, hooks_1.useContext)(Context);
exports.useExplorer = useExplorer;
