"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SoundHandler = void 0;
const THREE = require("three");
class SoundHandler {
    constructor() {
        this.listener = new THREE.AudioListener();
        this.audioLoader = new THREE.AudioLoader();
        this.crackle = [];
        this.boom = [];
        this.background = [];
        this.Init();
    }
    //
    Init() {
        const self = this;
        this.audioLoader.load('sounds/heaven.mp3', function (buffer) {
            self.background.push(buffer);
        });
    }
    PlayBackground(volume) {
        const buffer = this.background[0];
        this.Play(buffer, volume, true);
    }
    Play(buffer, volume, loop = false) {
        const sound = new THREE.Audio(this.listener);
        sound.setBuffer(buffer);
        sound.setLoop(loop);
        sound.setVolume(volume);
        sound.play();
    }
}
exports.SoundHandler = SoundHandler;
