"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMouseDragEvent = void 0;
const hooks_1 = require("preact/hooks");
function useMouseDragEvent(containerRef, handler, inputs) {
    const callback = (0, hooks_1.useCallback)(handler, inputs);
    const [isActive, setIsActive] = (0, hooks_1.useState)(false);
    const [isHover, setIsHover] = (0, hooks_1.useState)(false);
    (0, hooks_1.useEffect)(() => {
        function handleSliderMoveStart(ev) {
            document.addEventListener('mousemove', handleSliderMove);
            document.addEventListener('mouseup', handleSliderMoveEnd);
            setIsActive(true);
            callback(ev);
        }
        function handleSliderMove(ev) {
            callback(ev);
        }
        function handleSliderMoveEnd(ev) {
            document.removeEventListener('mousemove', handleSliderMove);
            document.removeEventListener('mouseup', handleSliderMoveEnd);
            setIsActive(false);
        }
        function handleMouseEnter() {
            setIsHover(true);
        }
        function handleMouseLeave() {
            setIsHover(false);
        }
        containerRef.current.addEventListener('mousedown', handleSliderMoveStart);
        containerRef.current.addEventListener('mouseenter', handleMouseEnter);
        containerRef.current.addEventListener('mouseleave', handleMouseLeave);
        return () => {
            document.removeEventListener('mousemove', handleSliderMove);
            document.removeEventListener('mouseup', handleSliderMoveEnd);
            containerRef.current.removeEventListener('mousedown', handleSliderMoveStart);
            containerRef.current.removeEventListener('mouseenter', handleMouseEnter);
            containerRef.current.removeEventListener('mouseleave', handleMouseLeave);
            setIsHover(false);
            setIsActive(false);
        };
    }, [callback]);
    return [isHover, isActive];
}
exports.useMouseDragEvent = useMouseDragEvent;
